<template>
  <div class="mb-40">
    <div
      class="relative overflow-hidden dark:bg-darker lg:overflow-auto"
      id="home"
    >
      <div class="absolute inset-x-0 top-32 lg:hidden-">
        <div
          aria-hidden="true"
          class="grid grid-cols-2 -space-x-52 opacity-50 dark:opacity-60 2xl:mx-auto 2xl:max-w-6xl"
        >
          <div
            class="h-60 bg-gradient-to-br from-orange-200 to-purple-400 blur-3xl dark:from-orange-700"
          />
          <div
            class="h-72 rounded-full bg-gradient-to-r from-cyan-400 to-sky-300 blur-3xl dark:from-transparent dark:to-orange-600"
          />
        </div>
      </div>
      <div class="mx-auto max-w-6xl px-6 md:px-12 lg:px-6 xl:px-0">
        <div class="relative ml-auto pt-40 xl:pt-36">
          <div class="gap-12 md:flex md:items-center">
            <div
              class="text-center sm:px-12 md:w-2/3 md:px-0 md:text-left lg:w-1/2"
            >
              <h1
                class="text-5xl font-black dark:text-white md:text-6xl xl:text-7xl"
              >
                A platform for the Community
              </h1>
              <div class="">
                <p class="mt-8 text-lg text-gray-700 dark:text-gray-100">
                  We connect people and industry leaders to build integrated
                  communities with rich and authentic lifestyle.
                </p>
                <div
                  class="mt-12 flex justify-center md:justify-start gap-4 sm:gap-6"
                >
                  <v-btn :to="{ name: 'sell' }" target="_blank" color="primary">
                    For business
                  </v-btn>
                  <v-btn :to="{ name: 'contact' }">Contact Us</v-btn>
                </div>
              </div>
            </div>
            <div class="relative mt-20 md:mt-0 md:w-2/5 lg:w-3/5">
              <div class="-md:ml-6 md:-mr-72 lg:mr-0">
                <img
                  class="w-full object-cover object-left rounded-xl overflow-hidden"
                  src="https://images.pexels.com/photos/2422287/pexels-photo-2422287.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                  alt="app screenshot"
                />
                <!-- <img
                  class="h-full object-cover object-left dark:hidden"
                  src="https://astrolus-premium.netlify.app/images/hero.webp"
                  alt="app screenshot"
                  width="1628"
                  height="1233"
                />
                <img
                  class="hidden h-full object-cover object-left dark:block"
                  src="https://astrolus-premium.netlify.app/images/hero-dark.webp"
                  alt="app screenshot"
                  width="1628"
                  height="1233"
                /> -->
              </div>
            </div>
          </div>

          <!-- <div class="mt-8 text-center md:mt-32 lg:mt-20 xl:mt-16">
            <span
              class="text-sm font-semibold tracking-wider text-gray-800 dark:text-white"
            >
              TRUSTED BY COMMUNITY LEADERS
            </span>
            <div
              class="mt-8 flex flex-wrap items-center justify-center gap-6 brightness-75 contrast-200 grayscale dark:brightness-200 dark:contrast-0 sm:justify-between lg:gap-24"
            >
              <img
                class="h-8 w-auto lg:h-14"
                src="https://astrolus-premium.netlify.app/images/clients/airbnb.svg"
                loading="lazy"
                alt="airbnb"
                width="100"
                height=""
              />
              <img
                class="h-6 w-auto lg:h-10"
                src="https://astrolus-premium.netlify.app/images/clients/google.svg"
                loading="lazy"
                alt="bissell"
                width="100"
                height=""
              />
              <img
                class="h-6 w-auto lg:h-10"
                src="https://astrolus-premium.netlify.app/images/clients/ge.svg"
                loading="lazy"
                alt="ge"
                width="100"
                height="100"
              />
              <img
                class="h-6 w-auto lg:h-10"
                src="https://astrolus-premium.netlify.app/images/clients/netflix.svg"
                loading="lazy"
                alt="lilly"
                width="100"
                height="100"
              />
              <img
                class="h-8 w-auto lg:h-14"
                src="https://astrolus-premium.netlify.app/images/clients/microsoft.svg"
                loading="lazy"
                alt="microsoft"
                width="100"
                height=""
              />
            </div>
          </div> -->
        </div>
      </div>
    </div>
    <div class="relative pt-32 md:pt-44" id="features">
      <div class="mx-auto max-w-6xl px-6 md:px-12 lg:px-6 xl:px-0">
        <div class="mx-auto md:w-3/5">
          <h2
            class="text-center text-3xl font-bold text-gray-900 dark:text-white md:text-4xl lg:text-5xl"
          >
            Our purpose is simple
          </h2>
          <p class="mt-4 text-6 text-center text-gray-600 dark:text-gray-300">
            We make it easy to find products, events and services offered by
            local businesses through our marketplace.
          </p>
        </div>
        <div class="mt-16 md:mt-20">
          <div
            class="relative grid rounded-3xl border border-gray-200 p-1 dark:border-gray-800 lg:grid-cols-2"
          >
            <div class="absolute inset-0 hidden h-max dark:block lg:my-auto">
              <div
                aria-hidden="true"
                class="grid grid-cols-2 -space-x-52 opacity-50 dark:opacity-70 2xl:mx-auto 2xl:max-w-6xl"
              >
                <div
                  class="h-60 bg-gradient-to-br from-primary to-purple-400 blur-3xl dark:from-orange-700"
                />
                <div
                  class="h-72 rounded-full bg-gradient-to-r from-cyan-400 to-sky-300 blur-3xl dark:from-transparent dark:to-orange-600"
                />
              </div>
            </div>
            <div>
              <div
                class="relative flex h-full flex-col items-center justify-center gap-6 p-8 py-16 lg:py-8"
              >
                <NuxtImg
                  class="w-16 h-16"
                  src="/img/icon/people.png"
                  height="512"
                  width="512"
                />
                <div class="mx-auto text-center sm:w-3/5">
                  <h2
                    class="text-xl font-semibold text-gray-900 dark:text-white"
                  >
                    Community centred
                  </h2>
                  <p class="mt-3 text-gray-600 dark:text-gray-400">
                    Through our services, we seek to deliver quality products to
                    our buyers, while making commerce easy for sellers, service
                    providers and event organisers.
                  </p>
                </div>
              </div>
            </div>
            <div
              class="relative grid overflow-hidden rounded-[1.25rem] bg-gray-100 p-1 dark:bg-gray-800/50 sm:grid-cols-2"
            >
              <div
                class="flex flex-col gap-6 rounded-2xl p-8 transition duration-300 hover:bg-white hover:shadow-2xl hover:shadow-gray-600/10 dark:hover:bg-gray-700/60 dark:hover:shadow-none"
              >
                <div
                  class="flex h-10 w-10 rounded border border-gray-200 dark:border-gray-700"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="m-auto h-6 w-6 text-gray-700 dark:text-white"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M21 7.5l-9-5.25L3 7.5m18 0l-9 5.25m9-5.25v9l-9 5.25M3 7.5l9 5.25M3 7.5v9l9 5.25m0-9v9"
                    ></path>
                  </svg>
                </div>
                <div>
                  <h2
                    class="text-xl font-semibold text-gray-900 dark:text-white"
                  >
                    Products
                  </h2>
                  <p class="mt-3 text-gray-600 dark:text-gray-400">
                    Browse curated products from sellers committed to delivering
                    best value products
                  </p>
                </div>
              </div>

              <div
                class="flex flex-col gap-6 rounded-2xl p-8 transition duration-300 hover:bg-white hover:shadow-2xl hover:shadow-gray-600/10 dark:hover:bg-gray-700/60 dark:hover:shadow-none"
              >
                <div
                  class="flex h-10 w-10 rounded border border-gray-200 dark:border-gray-700"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="m-auto h-6 w-6 text-gray-700 dark:text-white"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z"
                    ></path>
                  </svg>
                </div>
                <div>
                  <h2
                    class="text-xl font-semibold text-gray-900 dark:text-white"
                  >
                    Events
                  </h2>
                  <p class="mt-3 text-gray-600 dark:text-gray-400">
                    Find and buy event tickets to local events at lower prices
                  </p>
                </div>
              </div>

              <div
                class="flex flex-col gap-6 rounded-2xl p-8 transition duration-300 hover:bg-white hover:shadow-2xl hover:shadow-gray-600/10 dark:hover:bg-gray-700/60 dark:hover:shadow-none"
              >
                <div
                  class="flex h-10 w-10 rounded border border-gray-200 dark:border-gray-700"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="m-auto h-6 w-6 text-gray-700 dark:text-white"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M6 6.878V6a2.25 2.25 0 012.25-2.25h7.5A2.25 2.25 0 0118 6v.878m-12 0c.235-.083.487-.128.75-.128h10.5c.263 0 .515.045.75.128m-12 0A2.25 2.25 0 004.5 9v.878m13.5-3A2.25 2.25 0 0119.5 9v.878m0 0a2.246 2.246 0 00-.75-.128H5.25c-.263 0-.515.045-.75.128m15 0A2.25 2.25 0 0121 12v6a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 18v-6c0-.98.626-1.813 1.5-2.122"
                    ></path>
                  </svg>
                </div>
                <div>
                  <h2
                    class="text-xl font-semibold text-gray-900 dark:text-white"
                  >
                    Services
                  </h2>
                  <p class="mt-3 text-gray-600 dark:text-gray-400">
                    Our booking services let you find and book your appointments
                    anytime
                  </p>
                </div>
              </div>

              <div
                class="flex flex-col gap-6 rounded-2xl p-8 transition duration-300 hover:bg-white hover:shadow-2xl hover:shadow-gray-600/10 dark:hover:bg-gray-700/60 dark:hover:shadow-none"
              >
                <div
                  class="flex h-10 w-10 rounded border border-gray-200 dark:border-gray-700"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="m-auto h-6 w-6 text-gray-700 dark:text-white"
                  >
                    <!-- <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                    ></path> -->

                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M13.5 21v-7.5a.75.75 0 01.75-.75h3a.75.75 0 01.75.75V21m-4.5 0H2.36m11.14 0H18m0 0h3.64m-1.39 0V9.349m-16.5 11.65V9.35m0 0a3.001 3.001 0 003.75-.615A2.993 2.993 0 009.75 9.75c.896 0 1.7-.393 2.25-1.016a2.993 2.993 0 002.25 1.016c.896 0 1.7-.393 2.25-1.016a3.001 3.001 0 003.75.614m-16.5 0a3.004 3.004 0 01-.621-4.72L4.318 3.44A1.5 1.5 0 015.378 3h13.243a1.5 1.5 0 011.06.44l1.19 1.189a3 3 0 01-.621 4.72m-13.5 8.65h3.75a.75.75 0 00.75-.75V13.5a.75.75 0 00-.75-.75H6.75a.75.75 0 00-.75.75v3.75c0 .415.336.75.75.75z"
                    ></path>
                  </svg>
                </div>
                <div>
                  <h2
                    class="text-xl font-semibold text-gray-900 dark:text-white"
                  >
                    Business Directory
                  </h2>
                  <p class="mt-3 text-gray-600 dark:text-gray-400">
                    Business Directory gives you details and access to local
                    businesses near you.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="pt-32 md:pt-44" id="solution">
      <div class="mx-auto max-w-6xl px-6 md:px-12 lg:px-6 xl:px-0">
        <div
          class="flex-row-reverse items-center justify-between space-y-12 text-gray-600 md:flex md:gap-6 lg:gap-12 lg:space-y-0"
        >
          <div class="relative ml-auto md:w-1/2">
            <v-img
              class="elevation-3 rounded-lg overflow-hidden"
              src="https://images.pexels.com/photos/2422289/pexels-photo-2422289.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
              alt="app milestone"
              aspect-ratio="1"
              cover
            />
          </div>

          <div class="md:w-1/2 lg:w-[47%]">
            <h2
              class="text-3xl font-bold text-gray-900 dark:text-white md:text-4xl lg:text-5xl"
            >
              A complete commerce tool for small businesses
            </h2>
            <p class="my-12 text-gray-600 dark:text-gray-300">
              We bring together everything that’s required to build your
              business and promote your products and services to your African
              buyers.
              <br />
              <br />
              With Orie, you can sell your products on Marketplace as well as
              other sales channels, including your business brand website and
              apps.
            </p>
            <div
              class="space-y-4 divide-y divide-gray-100 dark:divide-gray-800"
            >
              <div class="mt-8 flex gap-4 md:items-center">
                <div
                  class="flex h-12 w-12 gap-4 rounded border border-gray-200 dark:border-gray-900"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="m-auto h-6 w-6 text-gray-700 dark:text-gray-300"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M15.75 10.5V6a3.75 3.75 0 10-7.5 0v4.5m11.356-1.993l1.263 12c.07.665-.45 1.243-1.119 1.243H4.25a1.125 1.125 0 01-1.12-1.243l1.264-12A1.125 1.125 0 015.513 7.5h12.974c.576 0 1.059.435 1.119 1.007zM8.625 10.5a.375.375 0 11-.75 0 .375.375 0 01.75 0zm7.5 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"
                    ></path>
                  </svg>
                </div>
                <div class="w-5/6">
                  <h3
                    class="text-lg font-semibold text-gray-700 dark:text-white"
                  >
                    E-commerce
                  </h3>
                  <p class="text-gray-500 dark:text-gray-400">
                    Sell online, in store, and around the world with Orie.
                    Easily collect payments and fulfill orders from anywhere.
                  </p>
                </div>
              </div>
              <div class="flex gap-4 pt-4 md:items-center">
                <div
                  class="flex h-12 w-12 gap-4 rounded border border-gray-200 dark:border-gray-900"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="m-auto h-6 w-6 text-gray-700 dark:text-gray-300"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M16.5 6v.75m0 3v.75m0 3v.75m0 3V18m-9-5.25h5.25M7.5 15h3M3.375 5.25c-.621 0-1.125.504-1.125 1.125v3.026a2.999 2.999 0 010 5.198v3.026c0 .621.504 1.125 1.125 1.125h17.25c.621 0 1.125-.504 1.125-1.125v-3.026a2.999 2.999 0 010-5.198V6.375c0-.621-.504-1.125-1.125-1.125H3.375z"
                    ></path>
                  </svg>
                </div>
                <div class="w-5/6">
                  <h3
                    class="text-lg font-semibold text-gray-700 dark:text-white"
                  >
                    Ticketing & Booking
                  </h3>
                  <p class="text-gray-500 dark:text-gray-400">
                    Organise events and manage service bookings online and in
                    person.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-20 md:mt-32">
          <h3
            class="text-2xl font-bold text-gray-900 dark:text-white md:w-2/3 md:text-3xl lg:text-4xl"
          >
            Our objectives
          </h3>
          <div class="relative mt-12 grid gap-6 sm:grid-cols-2 lg:grid-cols-3">
            <div
              class="relative overflow-hidden rounded-2xl bg-gradient-to-b from-gray-200 to-white p-px dark:from-gray-700 dark:via-gray-800 dark:to-darker"
            >
              <div
                class="relative flex h-full flex-col gap-6 rounded-2xl bg-gray-100 p-8 dark:bg-gray-900"
              >
                <NuxtImg
                  class="w-10 h-10"
                  src="/img/icon/partners.png"
                  height="512"
                  width="512"
                />
                <div>
                  <h4
                    class="text-xl font-semibold text-gray-900 dark:text-white"
                  >
                    Accessible
                  </h4>
                  <p class="mt-3 text-gray-600 dark:text-gray-400">
                    We believe that African products and services should be
                    easily accessible for everyone
                  </p>
                </div>
              </div>
            </div>
            <div
              class="relative overflow-hidden rounded-2xl bg-gradient-to-b from-gray-200 to-white p-px dark:from-gray-700 dark:via-gray-800 dark:to-darker"
            >
              <div
                class="relative flex h-full flex-col gap-6 rounded-2xl bg-gray-100 p-8 dark:bg-gray-900"
              >
                <NuxtImg
                  class="w-10 h-10"
                  src="/img/icon/diversity.png"
                  height="512"
                  width="512"
                />
                <div>
                  <h4
                    class="text-xl font-semibold text-gray-900 dark:text-white"
                  >
                    Community
                  </h4>
                  <p class="mt-3 text-gray-600 dark:text-gray-400">
                    We make commerce simple for passionate entrepreneurs and
                    enable community leaders to better connect with businesses
                    and the people
                  </p>
                </div>
              </div>
            </div>
            <div
              class="relative overflow-hidden rounded-2xl bg-gradient-to-b from-gray-200 to-white p-px dark:from-gray-700 dark:via-gray-800 dark:to-darker"
            >
              <div
                class="relative flex h-full flex-col gap-6 rounded-2xl bg-gray-100 p-8 dark:bg-gray-900"
              >
                <NuxtImg
                  class="w-10 h-10"
                  src="/img/icon/party.png"
                  height="512"
                  width="512"
                />
                <div>
                  <h4
                    class="text-xl font-semibold text-gray-900 dark:text-white"
                  >
                    Lifestyle
                  </h4>
                  <p class="mt-3 text-gray-600 dark:text-gray-400">
                    We aspired for a world where the diverse and rich African
                    culture is recognised and celebrated.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div
          class="mx-auto flex flex-col items-center gap-6 sm:w-4/5 md:w-full md:flex-row lg:gap-12"
        >
          <div class="relative md:w-1/2">
            <div
              aria-hidden="true"
              class="absolute inset-0 m-auto grid h-3/5 w-3/5 grid-cols-2 -space-x-52 opacity-40 dark:opacity-60"
            >
              <div
                class="h-full rounded-full bg-gradient-to-br from-primary to-purple-400 blur-[106px] dark:from-blue-700"
               />
              <div
                class="h-full bg-gradient-to-r from-cyan-400 to-primary blur-[106px] dark:to-indigo-600"
               />
            </div>
            <img
              class="w-full object-cover object-left rounded-xl overflow-hidden"
              src="https://images.pexels.com/photos/2422287/pexels-photo-2422287.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
              alt="app screenshot"
            />
          </div>
          <div class="ml-auto h-full md:w-1/2">
            <h3
              class="text-2xl font-bold text-gray-900 dark:text-white md:text-3xl lg:text-4xl"
            >
              Go beyond with our top products
            </h3>
            <p class="my-12 text-5 text-gray-600 dark:text-gray-300">
              Nobis minus voluptatibus pariatur dignissimos libero quaerat ?
              Asperiores nemo possimus nesciunt dicta veniam aspernatur quam
              mollitia. <br />
              <br />
              Vitae error, quaerat officia delectus voluptatibus explicabo quo
              pariatur impedit, at reprehenderit aliquam a ipsum quas
              voluptatem. Quo pariatur asperiores eum amet.
            </p>
          </div>
        </div> -->
      </div>
    </div>
    <div class="relative pt-32 md:pt-44" id="pricing">
      <div
        aria-hidden="true"
        class="absolute inset-0 m-auto grid h-max w-full grid-cols-2 -space-x-52 opacity-40 dark:opacity-80"
      >
        <div
          class="h-56 bg-gradient-to-br from-orange to-purple-400 blur-[106px] dark:from-orange-700"
        />
        <div
          class="h-32 bg-gradient-to-r from-cyan-400 to-sky-300 blur-[106px] dark:to-orange-600"
        />
      </div>
      <div class="mx-auto max-w-6xl px-6 md:px-12 lg:px-6 xl:px-0">
        <div class="m-auto text-center lg:w-8/12 xl:w-7/12">
          <h2
            class="text-3xl font-bold text-gray-900 dark:text-white md:text-4xl lg:text-5xl"
          >
            Start selling on Orie today
          </h2>
        </div>
        <div class="mt-12 md:mt-20">
          <div
            class="relative rounded-3xl border border-gray-100 bg-white shadow-2xl shadow-gray-600/20 transition duration-500 dark:border-white/10 dark:bg-gray-900 dark:shadow-none"
          >
            <div
              class="flex flex-col gap-12 divide-y p-12 dark:divide-gray-800 md:flex-row md:divide-y-0 md:divide-x"
            >
              <div class="text-center md:w-5/12">
                <h3 class="text-3xl font-bold text-gray-900 dark:text-white">
                  Join the Community
                </h3>
                <p class="text-lg text-gray-600 dark:text-gray-300">
                  Bring your business to the right people
                </p>
                <span
                  class="mb-6 mt-12 inline-block text-6xl font-bold text-gray-900 dark:text-white"
                  ><span class="text-4xl text-primary dark:text-gray-200"
                    >$</span
                  >0</span
                >

                <div class="flex justify-center">
                  <v-btn size="large" color="primary">Sign up</v-btn>
                </div>

                <p class="mt-12 text-sm text-gray-500 dark:text-gray-400">
                  No credit card required.
                </p>
              </div>
              <div class="relative md:w-7/12 md:pl-12">
                <ul
                  role="list"
                  class="list-none space-y-4 py-6 text-gray-600 dark:text-gray-300"
                >
                  <li class="space-x-2">
                    <span class="font-semibold text-primary dark:text-gray-300"
                      >✓</span
                    >
                    <span>Sell on multiple channels</span>
                  </li>
                  <li class="space-x-2">
                    <span class="font-semibold text-primary dark:text-gray-300"
                      >✓</span
                    >
                    <span>Manage your products in one place</span>
                  </li>
                  <li class="space-x-2">
                    <span class="font-semibold text-primary dark:text-gray-300"
                      >✓</span
                    >
                    <span>Promote your events and services</span>
                  </li>
                  <li class="space-x-2">
                    <span class="font-semibold text-primary dark:text-gray-300"
                      >✓</span
                    >
                    <span>Build and grow your business</span>
                  </li>
                </ul>
                <p class="text-gray-700 dark:text-white">
                  Team can be any size, and you can add or switch members as
                  needed.
                  <!-- Companies using our platform include: -->
                </p>
                <!-- <div
                  class="mt-12 flex flex-wrap items-center justify-between gap-6 grayscale"
                >
                  <img
                    class="h-8 w-auto lg:h-12"
                    src="https://astrolus-premium.netlify.app/images/clients/airbnb.svg"
                    width="100"
                    alt="airbnb"
                  />
                  <img
                    class="h-6 w-auto lg:h-8"
                    src="https://astrolus-premium.netlify.app/images/clients/netflix.svg"
                    width="100"
                    alt="bissell"
                  />
                  <img
                    class="h-8 w-auto lg:h-10"
                    src="https://astrolus-premium.netlify.app/images/clients/ge.svg"
                    width="100"
                    height="100"
                    alt="ge"
                  />
                  <img
                    class="ww-auto h-8 lg:h-12"
                    src="https://astrolus-premium.netlify.app/images/clients/microsoft.svg"
                    width="100"
                    alt="microsoft"
                  />
                </div> -->
              </div>
            </div>
          </div>
        </div>

        <div class="mt-20 flex flex-col gap-12 md:mt-32 lg:flex-row">
          <div class="text-center lg:w-5/12 lg:pl-12 lg:text-left">
            <h2
              class="text-2xl font-bold text-gray-800 dark:text-white md:text-3xl lg:text-4xl"
            >
              Frequently Asked Questions
            </h2>
            <p class="mt-4 text-gray-600 dark:text-gray-300">
              Here are some frequently asked questions about us.
              <br />
              Please contact us for more information.
            </p>
            <div class="mt-4">
              <v-btn :to="{ name: 'contact' }" color="primary" size="large"
                >Contact us</v-btn
              >
              <v-btn :to="{ name: 'help-faqs' }" class="ml-2" size="large"
                >FAQs</v-btn
              >
            </div>
          </div>
          <div
            class="divide-y divide-gray-200 border-y border-gray-200 dark:divide-gray-800 dark:border-gray-800 lg:w-7/12"
          >
            <v-card v-if="loadingFaqs" class="!pa-10 text-center" elevation="2">
              <v-progress-circular size="50" indeterminate width="1" />
            </v-card>
            <v-expansion-panels v-else variant="accordion">
              <v-expansion-panel
                v-for="faq in faqs"
                :key="faq.id"
                :title="faq.question"
              >
                <template #text>
                  <div v-html="faq.answer" />
                </template>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { PlatformAudience } from "@/models/common";

export default defineComponent({
  setup() {
    definePageMeta({
      headerDark: true,
      seo: {
        title: "About Us",
      },
    });

    const { loading: loadingFaqs, result: faqs } = useFaqs({
      limit: 5,
      tags: ["events", "privacy", "selling"],
      audience: PlatformAudience.ALL,
    });

    return {
      loadingFaqs,
      faqs,
    };
  },
});
</script>
