import { defineNuxtPlugin } from "#app";
import posthog, { type PostHog } from "posthog-js";

export default defineNuxtPlugin(() => {
  const runtimeConfig = useRuntimeConfig();
  const config = runtimeConfig.public.posthog;

  const client = posthog.init(config.publicKey, {
    api_host: config.host,
    autocapture: false,
    capture_pageview: false,
    person_profiles: "identified_only",
    loaded: (posthog) => {
      if (!isAppEnv("production")) {
        posthog.debug();
      }
    },
  });

  // super properties
  // posthog.register({
  //   // bio_site_handle: page.handle,
  //   // bio_site_id: page.id,
  //   // bio_site_name: page.name,
  //   // workspace: page.workspace,
  // });

  return {
    provide: {
      get posthog() {
        return client as PostHog;
      },
    },
  };
});
