import { useStorage } from "@vueuse/core";
// import config from "#sales";
import { resolveCurrency } from "../utils";

export const useCurrency = () => {
  const currencyValue = useStorage("currency", "AUD");
  // const currencyValue = useStorage("currency", config.defaultCurrency);
  const currency = computed(() => resolveCurrency(currencyValue.value));

  const set = (code: string) => (currencyValue.value = code);

  return {
    currencyValue,
    currency,
    set,
  };
};
