import { PlatformAudience } from "./models/common";
import type { ThemeTypeOptions } from "./stores/theme";

export default defineAppConfig({
  app: {
    name: "Marketplace",
    url: "orie.market",
    img: "/static/icon.png",
  },
  commerce: {
    defaultCurrency: "AUD",
    audient: PlatformAudience.BUYERS,
  },
  seo: {
    description:
      "Buy African products online. Find local events, services and things you’ll love. Support small businesses.",
    sellDescription:
      "Start your next business or grow an existing one free with Orie. Sell products, services and event tickets with tools to manage every part of your business.",
  },
  theme: {
    theme: "light" as ThemeTypeOptions,
    rtl: false,
  },
});
