import payload_plugin_qLmFnukI99 from "/builds/orieio/ui/marketplace/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_4sVQNw7RlN from "/builds/orieio/ui/marketplace/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/builds/orieio/ui/marketplace/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/builds/orieio/ui/marketplace/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/builds/orieio/ui/marketplace/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/builds/orieio/ui/marketplace/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/builds/orieio/ui/marketplace/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/builds/orieio/ui/marketplace/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/builds/orieio/ui/marketplace/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/builds/orieio/ui/marketplace/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/builds/orieio/ui/marketplace/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_tyiuIr3sFJ from "/builds/orieio/ui/marketplace/modules/sales/src/runtime/plugin.ts";
import plugin_ghbUAjaD3n from "/builds/orieio/ui/marketplace/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import plugin_iGLjBbor9q from "/builds/orieio/ui/marketplace/node_modules/nuxt-jsonld/dist/runtime/plugin.js";
import plugin_adVF2uRka6 from "/builds/orieio/ui/marketplace/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.js";
import pwa_icons_plugin_LnrhIJeMG7 from "/builds/orieio/ui/marketplace/.nuxt/pwa-icons-plugin.ts";
import pwa_client_ZuYw5i1i7i from "/builds/orieio/ui/marketplace/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import plugin_tbFNToZNim from "/builds/orieio/ui/marketplace/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import plugin_PSbK9A48ZQ from "/builds/orieio/ui/marketplace/node_modules/pinia-plugin-persistedstate/dist/nuxt/runtime/plugin.js";
import components_client_ZB1W0EeH0E from "/builds/orieio/ui/marketplace/node_modules/nuxt-swiper/dist/runtime/plugins/components.client.js";
import plugin_WLsn00x1qh from "/builds/orieio/ui/marketplace/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import unocss_MzCDxu9LMj from "/builds/orieio/ui/marketplace/.nuxt/unocss.mjs";
import loading_tYjkhBWuWf from "/builds/orieio/ui/marketplace/layers/ui/plugins/loading.ts";
import vee_validate_Dfac7c9cvC from "/builds/orieio/ui/marketplace/layers/ui/plugins/vee-validate.ts";
import index_JcX2pf9efO from "/builds/orieio/ui/marketplace/plugins/apollo/index.ts";
import packages_340RWGHlC8 from "/builds/orieio/ui/marketplace/plugins/packages.ts";
import posthog_ulvgx15Dgc from "/builds/orieio/ui/marketplace/plugins/posthog.ts";
import vee_validate_KcKlKmvCrJ from "/builds/orieio/ui/marketplace/plugins/vee-validate.ts";
import vuetify_7h9QAQEssH from "/builds/orieio/ui/marketplace/plugins/vuetify.ts";
export default [
  payload_plugin_qLmFnukI99,
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_tyiuIr3sFJ,
  plugin_ghbUAjaD3n,
  plugin_iGLjBbor9q,
  plugin_adVF2uRka6,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_ZuYw5i1i7i,
  plugin_tbFNToZNim,
  plugin_PSbK9A48ZQ,
  components_client_ZB1W0EeH0E,
  plugin_WLsn00x1qh,
  unocss_MzCDxu9LMj,
  loading_tYjkhBWuWf,
  vee_validate_Dfac7c9cvC,
  index_JcX2pf9efO,
  packages_340RWGHlC8,
  posthog_ulvgx15Dgc,
  vee_validate_KcKlKmvCrJ,
  vuetify_7h9QAQEssH
]