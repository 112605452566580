<template>
  <div>
    <BannerHero />
    <BrandsSection class="bg-white" />
    <PlatformServices />
    <PlatformAudience />
    <PlatformFeatures />
    <SellPricing />
    <GetStarted class="guide-divider" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import BannerHero from "@/components/heros/HomeBanner.vue";
import BrandsSection from "@/components/sections/Brands.vue";
import PlatformServices from "@/components/sections/PlatformServices.vue";
import PlatformAudience from "@/components/sections/PlatformAudience.vue";
import PlatformFeatures from "@/components/sections/PlatformFeatures.vue";
import SellPricing from "@/components/sections/SellPricing.vue";
import GetStarted from "@/components/sections/GetStarted.vue";

export default defineComponent({
  components: {
    BannerHero,
    BrandsSection,
    PlatformServices,
    PlatformAudience,
    PlatformFeatures,
    SellPricing,
    GetStarted,
  },
  setup() {
    definePageMeta({
      layout: "sell",
    });
  },
});
</script>
