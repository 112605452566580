<template>
  <PageLoader :loading="pending">
    <div class="min-h-screen md:bg-gray/20">
      <section class="mx-auto max-w-3xl">
        <div class="overflow-y-auto z-50">
          <div
            class="text-center flex items-end justify-center px-4 pb-20 pt-4 sm:block sm:p-0"
          >
            <div
              class="my-4 transition-opacity sm:my-0 inset-0"
              aria-hidden="true"
            >
              <div
                class="inline-block transform overflow-hidden rounded-lg lt-md:border sm:my-8 sm:max-w-xl bg-white px-8 pb-4 pt-5 text-left align-bottom transition-all sm:w-full sm:py-8 sm:align-middle"
                role="dialog"
                aria-modal="true"
                aria-labelledby="modal-headline"
              >
                <div
                  class="mx-auto flex items-center justify-center h-12 w-12 rounded-full"
                  :class="{
                    'bg-green/20 text-green': !isCancelled,
                    'bg-red/20 text-red': isCancelled,
                  }"
                >
                  <v-icon :icon="isCancelled ? 'close' : 'check'" />
                </div>
                <div class="mb-8 mt-6 text-center last:mb-0">
                  <h3
                    id="modal-headline"
                    class="text-emphasis text-2xl font-semibold leading-6"
                  >
                    {{
                      isCancelled
                        ? "This appointment is cancelled"
                        : "This appointment is scheduled"
                    }}
                  </h3>
                  <div v-if="!isCancelled" class="mt-3">
                    <p class="text-default">
                      We sent an email with a calendar invitation with the
                      details to everyone.
                    </p>
                  </div>
                  <div class="mt-8 grid grid-cols-3 border-t pt-8 text-left">
                    <div class="font-medium">What</div>
                    <div class="col-span-2 mb-6 last:mb-0">
                      {{ data.name }}
                    </div>
                    <div class="font-medium">When</div>
                    <div
                      class="col-span-2 mb-6 last:mb-0"
                      :class="{ 'line-through': isCancelled }"
                    >
                      <div class="">
                        {{ when.date }}<br />{{ when.time }}
                        <span v-if="when.timezone">({{ when.timezone }})</span>
                      </div>
                    </div>
                    <div class="font-medium">Who</div>
                    <div class="col-span-2 last:mb-0">
                      <div
                        v-for="(attendee, i) in attendees"
                        :key="`a-${i}`"
                        class="mb-3 last:mb-0"
                      >
                        <div v-if="attendee.host">
                          <span class="mr-2">{{ attendee.name }}</span>
                          <div
                            class="font-medium inline-flex items-center justify-center rounded gap-x-1 py-1 px-1.5 text-xs leading-3 bg-orange/10 text-orange"
                          >
                            Host
                          </div>
                        </div>
                        <p v-else>{{ attendee.name }}</p>
                        <p>{{ attendee.email }}</p>
                      </div>
                    </div>
                    <template v-if="!isCancelled">
                      <div class="mt-3 font-medium">Where</div>
                      <div class="col-span-2 mt-3">
                        {{ location.title }}
                      </div>
                    </template>
                  </div>
                  <div class="mt-8 text-left" />
                </div>
                <template v-if="!isCancelled">
                  <div class="border-t py-8 last:pb-0">
                    <div v-if="shouldCancel">
                      <div class="font-semibold mb-2">
                        Reason for cancellation (optional)
                      </div>
                      <v-textarea
                        v-model="cancellingReason"
                        class="mb-3"
                        placeholder="Why are you cancelling?"
                        rows="2"
                        :disabled="cancellingAppointment"
                        counter-value="320"
                        counter
                      />
                      <div class="flex justify-end">
                        <v-btn
                          :disabled="cancellingAppointment"
                          class="mr-2"
                          variant="flat"
                          @click="shouldCancel = false"
                        >
                          Nevermind
                        </v-btn>
                        <v-btn
                          :loading="cancellingAppointment"
                          color="black"
                          @click="cancelAppointment"
                        >
                          Cancel appointment
                        </v-btn>
                      </div>
                    </div>
                    <div v-else class="text-center">
                      <span class="text-emphasis mr-2">
                        Need to make a change?
                      </span>
                      <span class="inline">
                        <span class="underline">
                          <router-link :to="reschedule">Reschedule</router-link>
                        </span>
                        <span class="mx-2">or</span>
                      </span>
                      <button class="underline" @click="shouldCancel = true">
                        Cancel
                      </button>
                    </div>
                  </div>
                  <div
                    class="border-t align-center flex flex-row justify-center pt-8"
                  >
                    <span class="flex self-center font-medium mr-2">
                      Add to calendar
                    </span>
                    <div class="justify-left mt-1 flex gap-2 text-left sm:mt-0">
                      <a
                        v-for="link in links"
                        :key="link.id"
                        class="h-10 w-10 rounded-md border px-3 py-2 flex justify-center items-center"
                        :href="link.url"
                        :title="link.title"
                      >
                        <span><Icon :name="link.icon" size="30" /></span>
                      </a>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </PageLoader>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { joinURL } from "ufo";
import {
  type ServiceBooking,
  ServiceBookingStatus,
} from "@/services/service-booking";
import { get } from "lodash";
import dayjs from "dayjs";

const calendarLinkGroups = {
  google: {
    name: "Google",
    icon: "devicon:google",
  },
  yahoo: {
    name: "Yahoo",
    icon: "jam:yahoo",
    // icon: "logos:yahoo",
  },
  webOutlook: {
    name: "Web Outlook",
    icon: "vscode-icons:file-type-outlook",
  },
  ics: {
    //
  },
};

export default defineComponent({
  async setup() {
    definePageMeta({
      layout: "blank",
    });

    const route = useRoute();

    const cancellingAppointment = ref(false);
    const shouldCancel = ref(false);
    const cancellingReason = ref<string>();
    const status$1 = ref<ServiceBookingStatus>();

    const notFound = () => {
      // error
    };

    if (!route.query.reference || !route.query.email) {
      notFound();
    }

    const baseUrl = serverApiUrl("service/booking");

    const { data, error, pending, execute } =
      await useAsyncData<ServiceBooking>(() => {
        const url = joinURL(
          baseUrl,
          String(route.query.reference),
          String(route.query.email)
        );

        return $fetch<any>(url);
      });

    watch(error, notFound);
    onMounted(execute);

    const attendees = computed(() => {
      return data.value?.attendees || [];
    });

    const when = computed(() => {
      if (!data.value?.start_time || !data.value?.end_time) {
        return {};
      }

      const start = dayjs(data.value.start_time);
      const end = dayjs(data.value.end_time);
      const dtf = Intl.DateTimeFormat(undefined, { timeZoneName: "long" });
      const timezone = dtf
        .formatToParts(start.toDate())
        .find((part) => part.type == "timeZoneName")!.value;

      return {
        date: start.format("ddd, MMM D, YYYY"),
        time: start.format("HH:mm") + " - " + end.format("HH:mm"),
        timezone,
      };
    });

    const location = computed(() => {
      if (!data.value?.location) {
        return {};
      }
      return {
        title: data.value.location.value
          ? `${data.value.location.title} (${data.value.location.value})`
          : data.value.location.title,
        type: data.value.location.type,
      };
    });

    const links = computed(() => {
      if (!data.value?.calendarLinks) {
        return [];
      }

      return Object.entries(data.value.calendarLinks).map(([key, url]) => {
        return {
          id: key,
          title: get(calendarLinkGroups, [key, "title"], "Other"),
          icon: get(calendarLinkGroups, [key, "icon"], "oui:calendar"),
          url,
        };
      });
    });

    const reschedule = computed(() => {
      return {
        name: "services-reschedule",
        query: route.query,
      };
    });

    const isCancelled = computed(() => {
      const status = status$1.value || data.value?.status;
      return status == ServiceBookingStatus.CANCELLED;
    });

    const cancelAppointment = async () => {
      const url = serverApiUrl("service/cancel");
      cancellingAppointment.value = true;

      await $fetch<ServiceBookingStatus>(url, {
        method: "POST",
        body: {
          reference: route.query.reference,
          email: route.query.email,
          reason: cancellingReason.value,
        },
      })
        .then((status) => {
          status$1.value = status;
        })
        .catch(() => {
          // toast error
        })
        .finally(() => {
          cancellingAppointment.value = false;
        });
    };

    return {
      data,
      pending,
      attendees,
      when,
      location,
      links,
      reschedule,
      cancellingAppointment,
      shouldCancel,
      cancellingReason,
      isCancelled,
      cancelAppointment,
    };
  },
});
</script>
