<template>
  <Error404 v-if="is404" @redirect="redirect" />
  <ErrorPage v-else @redirect="redirect" :error="error" />
</template>

<script lang="ts">
import type { NuxtError } from "nuxt/app";
import { defineComponent, type PropType } from "vue";
import ErrorPage from "@/components/pages/errors/Error.vue";
import Error404 from "@/components/pages/errors/404.vue";

export default defineComponent({
  components: { ErrorPage, Error404 },
  props: {
    error: { type: Object as PropType<NuxtError>, required: true },
  },
  setup() {
    const redirect = () => clearError({ redirect: "/" });

    return {
      redirect,
    };
  },
  computed: {
    is404() {
      return this.error.statusCode === 404;
    },
  },
});
</script>
