<template>
  <GuideSection class="pt-20 md:pt-40 bg-white">
    <v-card v-if="loading" class="text-center py-200px" variant="flat">
      <v-progress-circular indeterminate width="1" />
    </v-card>
    <template v-else>
      <div class="pad-guides">
        <h1>FAQs — {{ faqGroup.name }}</h1>
      </div>
      <div class="md:grid md:grid-cols-2">
        <div v-for="faq in faqGroup.faqs" :key="faq.slug" class="pad-guides">
          <GuideText tag="h2" class="semibold text-4 mb-2">
            {{ faq.question }}
          </GuideText>
          <p class="faq-answer text-body-1" v-html="faq.answer" />
        </div>
      </div>
    </template>
    <div class="mt-5">
      <v-btn elevation="0" :to="{ name: 'help-faqs' }">
        <v-icon class="mr-2" size="small"> west </v-icon> Back to FAQs
      </v-btn>
    </div>
  </GuideSection>
  <GetStarted class="guide-divider" />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import GetStarted from "@/components/sections/GetStarted.vue";
import gql from "graphql-tag";
import { useQuery } from "@vue/apollo-composable";
import { PlatformAudience } from "@/models/common";

const QUERY = gql`
  query ($audience: PlatformAudience!, $where: FaqGroupWhereUniqueInput!) {
    publicFaqGroup(audience: $audience, where: $where) {
      id
      name
      slug
      description
      seo_title
      seo_description
      faqs {
        id
        question
        answer
      }
    }
  }
`;

export default defineComponent({
  components: {
    GetStarted,
  },
  setup() {
    const route = useRoute();
    const slug = computed(() => String(route.params.slug));
    const { loading, result } = useQuery<{ publicFaqGroup: any }>(
      QUERY,
      () => ({
        audience: PlatformAudience.BUYERS,
        where: { slug: slug.value },
      })
    );

    const faqGroup = computed(() => result.value?.publicFaqGroup || {});

    definePageMeta({
      headerDark: true,
      noAppHeaderPadding: true,
      // seo: () => {
      //   const title = faqGroup.value.name
      //     ? faqGroup.value.name + " | FAQ"
      //     : "FAQ";

      //   return {
      //     title,
      //   };
      // },
    });

    return {
      loading,
      faqGroup,
    };
  },
});
</script>

<style lang="scss">
.faq-answer li {
  @apply ml-5;
}
</style>
