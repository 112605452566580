
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as about6PTc13YVpxMeta } from "/builds/orieio/ui/marketplace/pages/about.vue?macro=true";
import { default as cartER3Ipc99sgMeta } from "/builds/orieio/ui/marketplace/pages/cart.vue?macro=true";
import { default as contactxdnaFeBvxAMeta } from "/builds/orieio/ui/marketplace/pages/contact.vue?macro=true";
import { default as _91handle_93mNbYS98YYfMeta } from "/builds/orieio/ui/marketplace/pages/events/[handle].vue?macro=true";
import { default as _91token_936KCbgL53q8Meta } from "/builds/orieio/ui/marketplace/pages/events/discounts/[token].vue?macro=true";
import { default as indexa4TGuS5tJCMeta } from "/builds/orieio/ui/marketplace/pages/events/index.vue?macro=true";
import { default as _91slug_93yqvE6aMmyQMeta } from "/builds/orieio/ui/marketplace/pages/help/faqs/[slug].vue?macro=true";
import { default as indexHWHaF0UkciMeta } from "/builds/orieio/ui/marketplace/pages/help/faqs/index.vue?macro=true";
import { default as indexAsGzvLXM7FMeta } from "/builds/orieio/ui/marketplace/pages/index.vue?macro=true";
import { default as _91handle_93WpWJbD0ZYwMeta } from "/builds/orieio/ui/marketplace/pages/legal/[handle].vue?macro=true";
import { default as indexArIznwvW5IMeta } from "/builds/orieio/ui/marketplace/pages/legal/index.vue?macro=true";
import { default as previewxphQJnxYdBMeta } from "/builds/orieio/ui/marketplace/pages/preview.vue?macro=true";
import { default as _91handle_934iGBng72OfMeta } from "/builds/orieio/ui/marketplace/pages/products/[handle].vue?macro=true";
import { default as indexwh5VcemUp4Meta } from "/builds/orieio/ui/marketplace/pages/products/index.vue?macro=true";
import { default as indexibiRgSaSpDMeta } from "/builds/orieio/ui/marketplace/pages/recipes/index.vue?macro=true";
import { default as registerjmxPYafnz8Meta } from "/builds/orieio/ui/marketplace/pages/register.vue?macro=true";
import { default as _91handle_93Gqq4L6JAjvMeta } from "/builds/orieio/ui/marketplace/pages/s/[handle].vue?macro=true";
import { default as appointmentssoWzD3BdxfMeta } from "/builds/orieio/ui/marketplace/pages/sell/appointments.vue?macro=true";
import { default as commercejNs1mXIHfIMeta } from "/builds/orieio/ui/marketplace/pages/sell/commerce.vue?macro=true";
import { default as contactpRVLNJYbLwMeta } from "/builds/orieio/ui/marketplace/pages/sell/contact.vue?macro=true";
import { default as events4xNRs1GWQAMeta } from "/builds/orieio/ui/marketplace/pages/sell/events.vue?macro=true";
import { default as indexQgc3deuctyMeta } from "/builds/orieio/ui/marketplace/pages/sell/index.vue?macro=true";
import { default as pricing6BmvgDtzdMMeta } from "/builds/orieio/ui/marketplace/pages/sell/pricing.vue?macro=true";
import { default as _91service_936jx88t8XEpMeta } from "/builds/orieio/ui/marketplace/pages/services/[provider]/[service].vue?macro=true";
import { default as index85IJDmvycnMeta } from "/builds/orieio/ui/marketplace/pages/services/[provider]/index.vue?macro=true";
import { default as bookingrtpeS8kXqZMeta } from "/builds/orieio/ui/marketplace/pages/services/booking.vue?macro=true";
import { default as indexFw4cCa99VNMeta } from "/builds/orieio/ui/marketplace/pages/services/index.vue?macro=true";
import { default as rescheduleMRHuT6RL0KMeta } from "/builds/orieio/ui/marketplace/pages/services/reschedule.vue?macro=true";
import { default as component_45stubwhquxxoSQdMeta } from "/builds/orieio/ui/marketplace/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubwhquxxoSQd } from "/builds/orieio/ui/marketplace/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "about",
    path: "/about",
    meta: about6PTc13YVpxMeta || {},
    component: () => import("/builds/orieio/ui/marketplace/pages/about.vue")
  },
  {
    name: "cart",
    path: "/cart",
    meta: cartER3Ipc99sgMeta || {},
    component: () => import("/builds/orieio/ui/marketplace/pages/cart.vue")
  },
  {
    name: "contact",
    path: "/contact",
    meta: contactxdnaFeBvxAMeta || {},
    component: () => import("/builds/orieio/ui/marketplace/pages/contact.vue")
  },
  {
    name: "events-handle",
    path: "/events/:handle()",
    meta: _91handle_93mNbYS98YYfMeta || {},
    component: () => import("/builds/orieio/ui/marketplace/pages/events/[handle].vue")
  },
  {
    name: "events-discounts-token",
    path: "/events/discounts/:token()",
    meta: _91token_936KCbgL53q8Meta || {},
    component: () => import("/builds/orieio/ui/marketplace/pages/events/discounts/[token].vue")
  },
  {
    name: "events",
    path: "/events",
    meta: indexa4TGuS5tJCMeta || {},
    component: () => import("/builds/orieio/ui/marketplace/pages/events/index.vue")
  },
  {
    name: "help-faqs-slug",
    path: "/help/faqs/:slug()",
    meta: _91slug_93yqvE6aMmyQMeta || {},
    component: () => import("/builds/orieio/ui/marketplace/pages/help/faqs/[slug].vue")
  },
  {
    name: "help-faqs",
    path: "/help/faqs",
    meta: indexHWHaF0UkciMeta || {},
    component: () => import("/builds/orieio/ui/marketplace/pages/help/faqs/index.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexAsGzvLXM7FMeta || {},
    component: () => import("/builds/orieio/ui/marketplace/pages/index.vue")
  },
  {
    name: "legal-handle",
    path: "/legal/:handle()",
    meta: _91handle_93WpWJbD0ZYwMeta || {},
    component: () => import("/builds/orieio/ui/marketplace/pages/legal/[handle].vue")
  },
  {
    name: "legal",
    path: "/legal",
    meta: indexArIznwvW5IMeta || {},
    component: () => import("/builds/orieio/ui/marketplace/pages/legal/index.vue")
  },
  {
    name: "preview",
    path: "/preview",
    meta: previewxphQJnxYdBMeta || {},
    component: () => import("/builds/orieio/ui/marketplace/pages/preview.vue")
  },
  {
    name: "products-handle",
    path: "/products/:handle()",
    meta: _91handle_934iGBng72OfMeta || {},
    component: () => import("/builds/orieio/ui/marketplace/pages/products/[handle].vue")
  },
  {
    name: "products",
    path: "/products",
    meta: indexwh5VcemUp4Meta || {},
    component: () => import("/builds/orieio/ui/marketplace/pages/products/index.vue")
  },
  {
    name: "recipes",
    path: "/recipes",
    component: () => import("/builds/orieio/ui/marketplace/pages/recipes/index.vue")
  },
  {
    name: "register",
    path: "/register",
    meta: registerjmxPYafnz8Meta || {},
    component: () => import("/builds/orieio/ui/marketplace/pages/register.vue")
  },
  {
    name: "s-handle",
    path: "/s/:handle()",
    meta: _91handle_93Gqq4L6JAjvMeta || {},
    component: () => import("/builds/orieio/ui/marketplace/pages/s/[handle].vue")
  },
  {
    name: "sell-appointments",
    path: "/sell/appointments",
    meta: appointmentssoWzD3BdxfMeta || {},
    component: () => import("/builds/orieio/ui/marketplace/pages/sell/appointments.vue")
  },
  {
    name: "sell-commerce",
    path: "/sell/commerce",
    meta: commercejNs1mXIHfIMeta || {},
    component: () => import("/builds/orieio/ui/marketplace/pages/sell/commerce.vue")
  },
  {
    name: "sell-contact",
    path: "/sell/contact",
    meta: contactpRVLNJYbLwMeta || {},
    component: () => import("/builds/orieio/ui/marketplace/pages/sell/contact.vue")
  },
  {
    name: "sell-events",
    path: "/sell/events",
    meta: events4xNRs1GWQAMeta || {},
    component: () => import("/builds/orieio/ui/marketplace/pages/sell/events.vue")
  },
  {
    name: "sell",
    path: "/sell",
    meta: indexQgc3deuctyMeta || {},
    component: () => import("/builds/orieio/ui/marketplace/pages/sell/index.vue")
  },
  {
    name: "sell-pricing",
    path: "/sell/pricing",
    meta: pricing6BmvgDtzdMMeta || {},
    component: () => import("/builds/orieio/ui/marketplace/pages/sell/pricing.vue")
  },
  {
    name: "services-provider-service",
    path: "/services/:provider()/:service()",
    meta: _91service_936jx88t8XEpMeta || {},
    component: () => import("/builds/orieio/ui/marketplace/pages/services/[provider]/[service].vue")
  },
  {
    name: "services-provider",
    path: "/services/:provider()",
    meta: index85IJDmvycnMeta || {},
    component: () => import("/builds/orieio/ui/marketplace/pages/services/[provider]/index.vue")
  },
  {
    name: "services-booking",
    path: "/services/booking",
    meta: bookingrtpeS8kXqZMeta || {},
    component: () => import("/builds/orieio/ui/marketplace/pages/services/booking.vue")
  },
  {
    name: "services",
    path: "/services",
    meta: indexFw4cCa99VNMeta || {},
    component: () => import("/builds/orieio/ui/marketplace/pages/services/index.vue")
  },
  {
    name: "services-reschedule",
    path: "/services/reschedule",
    component: () => import("/builds/orieio/ui/marketplace/pages/services/reschedule.vue")
  },
  {
    name: component_45stubwhquxxoSQdMeta?.name,
    path: "/ecommerce",
    component: component_45stubwhquxxoSQd
  },
  {
    name: component_45stubwhquxxoSQdMeta?.name,
    path: "/faq",
    component: component_45stubwhquxxoSQd
  },
  {
    name: component_45stubwhquxxoSQdMeta?.name,
    path: "/faqs",
    component: component_45stubwhquxxoSQd
  },
  {
    name: component_45stubwhquxxoSQdMeta?.name,
    path: "/contact-us",
    component: component_45stubwhquxxoSQd
  },
  {
    name: component_45stubwhquxxoSQdMeta?.name,
    path: "/gamesnight",
    component: component_45stubwhquxxoSQd
  }
]