<template>
  <div>
    <BannerHero />
    <BrandsSection />
    <YourBusiness />
    <PlatformAudience />
    <AppointmentsComparison />
    <GetStarted
      class="guide-divider"
      cta="Try scheduling free"
      heading="Start scheduling with Orie. No credit card required."
      caption="Get started for free and get instant access to the tools you need to manage your services and grow your business."
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import BannerHero from "@/components/heros/AppointmentsBanner.vue";
import BrandsSection from "@/components/sections/Brands.vue";
import YourBusiness from "@/components/sections/AppointmentsYourBusiness.vue";
import PlatformAudience from "@/components/sections/PlatformAudience.vue";
import AppointmentsComparison from "@/components/sections/AppointmentsComparison.vue";
import GetStarted from "@/components/sections/GetStarted.vue";

export default defineComponent({
  components: {
    BannerHero,
    YourBusiness,
    BrandsSection,
    PlatformAudience,
    AppointmentsComparison,
    GetStarted,
  },
  setup() {
    const keywords = seoKeywords();

    definePageMeta({
      layout: "sell",
      seo: {
        title: "Orie Appointments",
        description:
          "Online booking system for your business. Let clients schedule appointments, get reminders and securely pay online. Get started free!",
        keywords: keywords.sell.services,
      },
    });
  },
});
</script>
