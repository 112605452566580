<template>
  <GuideSection class="pt-20 md:pt-40 bg-white">
    <div class="relative">
      <div class="absolute inset-0 bg-img">
        <!--  -->
      </div>

      <div class="pad-guides md:flex md:justify-center">
        <div class="md:max-w-200 text-center">
          <h1 class="section-title">Orie FAQ</h1>

          <div class="text-5 md:max-w-200 mt-10 md:px-20">
            Get help or find answers to some frequently asked <br />
            questions about Orie.
          </div>
        </div>
      </div>
      <div class="pad-guides md:flex md:justify-center">
        <div class="md:w-100 mt-20 md:mt-10">
          <FaqSearch :groups="faqGroups" @open="open" @ask="ask" />
        </div>
      </div>
    </div>

    <div class="pad-guides md:flex md:justify-center">
      <div class="md:w-150">
        <div class="my-4">
          <v-card
            class="overflow-hidden"
            rounded="lg"
            :class="{ 'text-center': loading }"
            elevation="3"
          >
            <v-progress-circular
              v-if="loading"
              class="my-10"
              indeterminate
              width="1"
            />
            <FaqGroupCollection v-else ref="collection" :groups="faqGroups" />
          </v-card>
        </div>
        <div class="mt-10">
          <FaqsAsk ref="faqAsk" v-model="openAsk" :question="question" />
        </div>
      </div>
    </div>
  </GuideSection>

  <GuideSection
    class="pt-20 bg-black text-white"
    guide-solid-color="#181818"
    guide-dashed-color="#181818"
  >
    <div class="md:grid md:grid-cols-4">
      <div class="col-span-2 pad-guides md:pr-4">
        <GuideText class="font-bold text-primary" border-color="white">
          Ready to get started?
        </GuideText>

        <h2
          class="mt-8 section-title- text-8 font-semibold line-height-9 md:max-w-130"
        >
          Start selling for free in matter of minutes. No credit card required.
        </h2>

        <p class="mt-4 md:max-w-200">
          Orie is free to use. You only pay for what you need, when you need it.
        </p>
      </div>

      <div class="col-span-2">
        <GetStartedWithEmail class="pad-guides" />
      </div>
    </div>
  </GuideSection>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import GetStartedWithEmail from "@/components/elements/GetStartedWithEmail.vue";
import FaqSearch from "@/components/pages/faqs/FaqSearch.vue";
import FaqGroupCollection from "@/components/pages/faqs/FaqGroupCollection.vue";
import FaqsAsk from "@/components/pages/faqs/FaqsAsk.vue";

export default defineComponent({
  components: {
    GetStartedWithEmail,
    FaqSearch,
    FaqGroupCollection,
    FaqsAsk,
  },
  setup() {
    definePageMeta({
      headerDark: true,
      noAppHeaderPadding: true,
      seo: {
        title: "Orie FAQs",
      },
    });

    const { loading, faqGroups } = useFaqGroups();

    return {
      faqGroups,
      loading,
    };
  },
  data: () => ({
    openAsk: false,
    question: "",
  }),
  methods: {
    open(value: any) {
      // @ts-expect-error TODO add type to ref
      this.$refs.collection.open(value);
    },
    ask(question: string) {
      this.question = question;
      this.openAsk = true;
    },
  },
});
</script>

<style lang="scss" scoped>
@use "/assets/styles/breakpoints";

.bg-img {
  background-image: url(/img/search_header_background.svg);
  background-size: 90% auto;
  background-repeat: no-repeat;
  background-position: 50% 70%;
  @include breakpoints.up(md) {
    background-position: 50% 100%;
  }
}
</style>
