export default defineComponent({
  name: "RouteLink",
  props: {
    text: { type: String },
    to: {},
    href: { type: String },
    target: { type: String },
    decoration: { type: Boolean, default: false },
    dotted: { type: Boolean, default: false },
    email: { type: String },
    phone: { type: String },
  },
  render() {
    const getClass = () => {
      const cls: string[] = [];

      if (this.dotted) {
        cls.push("underline-dotted");
      } else if (!this.decoration) {
        cls.push("decoration-none no-underline");
      }

      const c = String(this.$attrs.class || "").split(" ");
      const x = !c.find((i) => i.startsWith("text-"));
      if (x) {
        cls.push("text-inherit");
      }

      return cls;
    };

    if (this.href) {
      return h(
        "a",
        {
          href: this.href,
          target: this.target,
          class: getClass(),
        },
        this.$slots.default || [this.text]
      );
    }

    if (this.to) {
      const RouterLink = resolveComponent("RouterLink");
      return h(
        RouterLink,
        {
          to: this.to,
          class: getClass(),
        },
        this.$slots.default || [this.text]
      );
    }

    if (this.email) {
      return h(
        "a",
        {
          href: `mailto:${this.email}`,
          target: this.target,
          class: getClass(),
        },
        this.$slots.default || [this.text || this.email]
      );
    }

    if (this.phone) {
      return h(
        "a",
        {
          href: `tel:${this.phone}`,
          target: this.target,
          class: getClass(),
        },
        this.$slots.default || [this.text || `Call ${this.phone}`]
      );
    }

    return h("span", [this.text]);
  },
});
