<template>
  <v-container class="mb-20">
    <ProfileImages v-if="media.length" :media="media" />
    <div class="mt-4">
      <h1 class="text-6">{{ profile.name }}</h1>
      <div
        v-if="profile.location"
        class="caption mb-1 text-orange-500 opacity-[0.9] text-3.5"
      >
        <v-icon icon="place" size="15" />
        <span class="ml-1">{{ profile.location.address_line }}</span>
      </div>
      <p v-if="profile.description">{{ profile.description }}</p>
    </div>
    <div class="mt-4 md:flex gap-10">
      <div class="md:w-3/5">
        <ServiceProfileServices
          v-model:selection="selection"
          class="mt-4"
          :priced="priced"
          :has-media="hasMedia"
          :groups="groups"
          :view-all="viewAll"
        />

        <div v-if="services.length > 4" class="mt-4">
          <v-btn @click="toggleViewAll" variant="flat">
            {{ viewAll ? "View less" : "View all" }}
          </v-btn>
        </div>
      </div>
      <div class="mt-10 md:w-2/5">
        <SelectedServicesSummary
          class="sticky top-20"
          :selection="selection"
          :priced="priced"
          :services="services"
          @continue="continueToBooking"
        />
      </div>
    </div>

    <div v-if="profile.about">
      <h3 class="mt-4">About</h3>
      <div class="mt-2" v-html="profile.about" />
    </div>
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ProfileImages from "@/components/elements/ProfileImages.vue";
import ServiceProfileServices from "@/components/elements/service/ServiceProfileServices.vue";
import SelectedServicesSummary from "@/components/elements/service/SelectedServicesSummary.vue";
import {
  MULTI_SERVICE_ROUTE_KEY,
  SERVICE_ROUTE_KEY,
  SERVICE_VARIANT_ROUTE_KEY,
} from "@/components/pages/service/lib";
import type { ServiceProfile } from "@/models/service";
import { useSalesApi } from "@/services/sales-api";

export default defineComponent({
  components: {
    ProfileImages,
    ServiceProfileServices,
    SelectedServicesSummary,
  },
  async setup() {
    definePageMeta({
      layout: "service",
      headerDark: true,
      noMobileNav: true,
      disableHeaderStick: true,
    });

    const api = useSalesApi();
    const route = useRoute();
    const router = useRouter();
    const provider = route.params.provider as string;
    const viewAll = ref(false);

    onMounted(() => {
      viewAll.value = route.query.collapse !== "true";
    });

    const data = await api.services.profile(provider);

    const profile = computed((): ServiceProfile => {
      if (data) {
        return data;
      }
      return {
        id: 0,
        handle: provider,
        name: provider,
        gallery: [],
        services: [],
      };
    });
    const selection = ref<string[]>([]);

    const services = computed(() => profile.value.services || []);
    const groupedServices = computed(() => groupServices(services.value));
    const groups = computed(() => groupedServices.value.groups);
    const priced = computed(() => groupedServices.value.priced);
    const hasMedia = computed(() => groupedServices.value.hasMedia);

    const _images = computed(() => {
      const items: any[] = [];

      profile.value.services.forEach((service) => {
        if (service.image) {
          // items.push(service.image);
        }
        if (service.gallery) {
          // items.push(...service.gallery);
        }

        service.variants.forEach((variant) => {
          if (variant.image) {
            // items.push(variant.image);
          }
          if (variant.gallery) {
            // items.push(...variant.gallery);
          }
        });
      });

      return items;
    });

    const media = computed(() => {
      const items: any[] = [];

      profile.value.services.forEach((service) => {
        if (service.video_url) {
          items.push({ videoUrl: service.video_url });
        }
      });

      if (profile.value.cover_image) {
        items.push(profile.value.cover_image);
      }
      if (profile.value.gallery) {
        items.push(...profile.value.gallery);
      }

      profile.value.services.forEach((service) => {
        if (service.image) {
          items.push(service.image);
        }
        if (service.gallery) {
          items.push(...service.gallery);
        }
        // "variants.image",
        // "variants.gallery",
      });

      return items;
    });

    const continueToBooking = () => {
      const query = {
        menu: "false",
        ...route.query,
      };

      if (selection.value.length > 1) {
        router.push({
          name: "services-provider-service",
          params: {
            provider: profile.value.handle,
            service: MULTI_SERVICE_ROUTE_KEY,
          },
          query: {
            ...query,
            [SERVICE_ROUTE_KEY]: selection.value.join(","),
          },
        });
      } else {
        const [service, variant] = String(selection.value[0]).split("/");
        const variantQuery: any = {};
        if (variant) {
          variantQuery[SERVICE_VARIANT_ROUTE_KEY] = variant;
        }
        router.push({
          name: "services-provider-service",
          params: {
            provider: profile.value.handle,
            service: service,
          },
          query: {
            ...query,
            ...variantQuery,
          },
        });
      }
    };

    return {
      priced,
      hasMedia,
      selection,
      services,
      profile,
      groups,
      media,
      viewAll,
      continueToBooking,
    };
  },
  methods: {
    toggleViewAll() {
      this.viewAll = !this.viewAll;
    },
  },
});
</script>
