<template>
  <div class="overflow-hidden relative z-10">
    <div class="top-10 right-[-30rem] absolute opacity-[0.1]">
      <Logo color="grey" size="900" lucid />
    </div>
    <div class="w-full !h-[70vh] md:!h-[90vh]">
      <div class="block overflow-hidden z-0 relative h-full">
        <v-container class="survey-content">
          <SurveyForm
            class="form-frame"
            :options="form"
            completed-text="Thank you for contacting us. We'll get back to you as soon as possible."
          />
        </v-container>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { defineSurveyForm } from "@/services/survey-form";
import SurveyForm from "@/services/survey-form/components/SurveyForm.vue";

export default defineComponent({
  components: { SurveyForm },
  setup() {
    definePageMeta({
      headerDark: true,
      seo: {
        title: "Contact Us",
      },
    });

    const url = serverApiUrl("forms/contact");

    const onSubmit = async (input: any) => {
      const { data } = await useFetch<any>(url, {
        method: "post",
        body: input,
      });
      if (!data.value.ok) {
        throw new Error("Failed to submit contact form");
      }
    };

    const form = defineSurveyForm({
      onSubmit,
      title: "Contact us",
      greeting: "Hello, Nnọọ, Habari, Pẹlẹ o",
      intro:
        "Have questions or need to resolve an issue with our service? Send us a message.",
      pages: [
        {
          questions: [
            {
              label: "What's your <strong>full name</strong>?",
              name: "Name",
              rules: "required|max:36",
              props: {
                placeholder: "Type your answer here",
              },
            },
          ],
        },
        {
          questions: [
            {
              name: "Email",
              label:
                "Hi, {{name}}! What's your <strong>business email</strong>?",
              rules: "required|email",
              props: {
                placeholder: "okafọ@example.com",
              },
            },
          ],
        },
        {
          questions: [
            {
              name: "Business",
              label: "What's the name of your <strong>company / shop</strong>?",
              rules: "max:36",
              props: {
                placeholder: "My Company Pty Ltd",
              },
            },
          ],
        },
        {
          questions: [
            {
              key: "body",
              name: "Details",
              type: "textarea",
              label:
                "Let us know how we can help you. The more information you are able to provide, the better.",
              subtitle:
                "For example, do you have questions about Orie for business, an existing order, or Orie services?",
              rules: "required|max:500",
              props: {
                placeholder: "Type your answer here",
                counter: 500,
              },
            },
          ],
        },
      ],
    });

    return {
      form,
    };
  },
});
</script>

<style lang="scss" scoped>
.survey-content {
  width: 100%;
  height: 100%;
  position: relative;
}
.survey-content .form-frame {
  width: 100%;
  height: 100%;
  border: none;
  overflow: hidden;
  border-radius: 8px;
}
.survey-content-close {
  display: none;
}
.survey-content-fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10001;
  width: 100% !important;
  min-height: 100% !important;
  width: 100vw !important;
  min-height: 100vh !important;
}
.survey-content-fullscreen .survey-content-close {
  display: block;
  position: absolute;
  font-size: 32px;
  line-height: 24px;
  width: 24px;
  height: 24px;
  text-align: center;
  cursor: pointer;
  opacity: 0.75;
  transition: opacity 0.25s ease-in-out;
  text-decoration: none;
  color: #000;
  top: 6px;
  right: 8px;
  z-index: 1;
}
.survey-content-fullscreen .survey-content-close:hover {
  opacity: 1;
}
@media (min-width: 481px) {
  .survey-content-fullscreen .survey-content-close {
    color: #fff !important;
  }
}
.survey-content-fullscreen .form-frame {
  border-radius: 0;
}
</style>
