const keywords = {
  general: [
    "Orie",
    "adelaide",
    "australia", //"uk", "us", "united states", "united kingdom", "new zealand",
  ],
  sell: ["best", "free", "crm"],
  brands: {
    commerce: ["Shopify", "Square", "Etsy", "Amazon"],
    services: [
      "Fresha",
      "Acuity",
      "Shedul",
      "mindbody",
      "booksy",
      "Square Appointments",
    ],
    events: ["Eventbrite", "TryBooking"],
  },
  features: {
    commerce: [
      "pos",
      "crm",
      "inventory systems",
      "inventory",
      "orders",
      "ecommerce",
      "commerce",
      "product",
      "product management system",
    ],
    services: [
      "salon system",
      "spa software",
      "beauty software",
      "salon app",
      "scheduling app",
      "hair salon software",
      "best salon software",
      "Salon system",
      "top salon software",
      "Spa system",
      "Appointment Scheduling",
      "salon program",
      "booking software",
      "system",
      "appointment system",
      "scheduling software",
      "online booking",
      "salon program",
      "salon management software",
      "salon programs",
      "scheduling applications",
      "appointment schedule",
      "appointment manager",
      "booking app",
      "nail software",
      "spa",
      "hair",
      "nail salon",
      "beauty",
      "yoga",
      "point of sale",
      "calendar system",
      "calendar",
      "reminders",
      "salon supplies",
      "salon management",
      "bookings app",
      "appointment app",
      "salon software",
      "best salons",
      "free booking system",
      "salon",
      "appointment",
      "scheduling",
    ],
    events: [
      "african events",
      "online bookings",
      "ticketing system",
      "online ticketing software",
      // "event ticketing company",
      "online ticket booking",
      "event booking system",
      "event ticketing system",
      "event ticketing software",
      "easy event ticketing",
      "event ticket booking site",
      "online ticket booking system",
      "online ticketing platform",
      "ticketing platform for african events",
      "sell event tickets online",
      "event ticketing platform",
      // "online donations",
      // "ticketing solutions",
      "event ticketing adelaide",
    ],
  },
};

export const seoKeywords = () => ({
  ...keywords,
  home: [...keywords.general],
  sell: {
    home: [
      ...keywords.general,
      ...keywords.sell,
      ...keywords.brands.commerce,
      ...keywords.brands.services,
      ...keywords.brands.events,
      ...keywords.features.commerce,
      ...keywords.features.services,
      ...keywords.features.events,
    ],
    commerce: [
      ...keywords.general,
      ...keywords.sell,
      ...keywords.brands.commerce,
      ...keywords.features.commerce,
    ],
    services: [
      ...keywords.general,
      ...keywords.sell,
      ...keywords.brands.services,
      ...keywords.features.services,
    ],
    events: [
      ...keywords.general,
      ...keywords.sell,
      ...keywords.brands.events,
      ...keywords.features.events,
    ],
  },
});
