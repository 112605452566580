<template>
  <PageLoader :loading="loading">
    <ProductPage v-if="product" :product="product" />
  </PageLoader>
</template>

<script lang="ts">
import { storeToRefs } from "pinia";
import { defineComponent } from "vue";
import PageLoader from "@/components/pages/PageLoader";
import ProductPage from "@/components/pages/product";
import jsonLd from "@/services/jsonld/product";
import { useProductCheckoutStore } from "@/stores/product-checkout";
import { useAvailability } from "@/services/resource-availability";

export default defineComponent({
  components: {
    PageLoader,
    ProductPage,
  },
  async setup() {
    definePageMeta({
      layout: "service",
      // noMobileNav: true,
      // disableHeaderStick: true,
    });

    const store = useProductCheckoutStore();
    const route = useRoute();
    const resourceAvailability = useAvailability();
    const { trackProductView } = usePageview();

    const handle = String(route.params.handle);
    store.handle = handle;

    const handle404 = (): never => {
      throw createError({
        statusCode: 404,
        statusMessage: "Product Not Found",
      });
    };

    const { loaded, product, availability, loading } = storeToRefs(store);

    store.loadingAvailability = true;
    resourceAvailability
      .product(handle)
      .then((result) => {
        availability.value = result;
      })
      .catch(() => {
        // TODO: log error
      })
      .finally(() => {
        store.loadingAvailability = false;
      });

    onMounted(() => {
      store.fetch(handle).then(trackProductView).catch(handle404);
    });

    useJsonld(jsonLd(product));

    useSeoMeta({
      title: product.value?.seo_title || product.value?.name,
      ogTitle: product.value?.seo_title || product.value?.name,
      description: product.value?.seo_description,
      ogDescription: product.value?.seo_description,
      publisher: product.value?.store?.name,
      ogImage: product.value?.image?.src,
      twitterCard: "summary_large_image",
    });

    return {
      loaded,
      product,
      loading,
    };
  },
});
</script>
