<template>
  <section v-if="page" class="py-8 bg-white lg:py-24 dark:bg-gray-900">
    <div class="max-w-3xl px-4 mx-auto format dark:format-invert lg:px-4">
      <h1
        class="mb-6 text-3xl font-bold text-gray-900 lg:text-4xl dark:text-white"
      >
        {{ page.title }}
      </h1>
      <ContentRenderer class="legal-content" :value="page" />
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  async setup() {
    definePageMeta({
      // disable sticky in header
      disableHeaderStick: true,
      headerDark: true,
    });

    const { path } = useRoute();

    // const { page } = useContent();
    const { data: page } = await useAsyncData(`legal-${path}`, () =>
      queryContent("legal").where({ _path: path }).findOne()
    );

    if (!page.value) {
      throw createError({
        statusCode: 404,
      });
    }

    return {
      page,
    };
  },
});
</script>

<style lang="scss">
.legal-content {
  h1,
  h2 {
    @apply mt-6.5 mb-4;
  }
  ol,
  ul {
    @apply ml-6;
  }
  a {
    @apply text-blue-600 no-underline dark:text-blue-400 hover:underline;
  }
  p:first-child {
    // excerpt
    @apply mb-12 pb-10 text-lg text-gray-600 dark:text-gray-400 lg:text-lg;
    border-bottom: 1px solid #a5a5a53b;
  }

  ol.sectioned-list {
    margin: 0.6em 0;
    &,
    ol {
      counter-reset: item;
      list-style-type: none;
      padding: 0;
    }
    @for $i from 1 through 100 {
      &[start="#{$i}"] {
        counter-reset: item $i - 1;
      }
    }
    li {
      counter-increment: item;
      display: table;
      margin-bottom: 0.6em;
    }
    li::before {
      content: attr(section) "." counters(item, ".");
      padding-right: 1em;
      display: table-cell;
    }
  }
}
</style>
