import { createVuetify, type ThemeDefinition } from "vuetify";
import { VTimePicker } from "vuetify/labs/VTimePicker";
import { VNumberInput } from "vuetify/labs/VNumberInput";
import colors from "vuetify/lib/util/colors.mjs";
import { aliases, md } from "vuetify/iconsets/md";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import "vuetify/styles";

export const lightTheme: ThemeDefinition = {
  dark: false,
  colors: {
    primary: colors.orange.darken2,
    info: colors.indigo.lighten1,
  },
};

export const darkTheme: ThemeDefinition = {
  dark: true,
  colors: {
    primary: colors.orange.darken2,
    background: colors.shades.black,
    info: colors.teal.lighten1,
  },
};

export default defineNuxtPlugin(({ vueApp }) => {
  const vuetify = createVuetify({
    ssr: false,
    defaults: {
      VBtn: {
        rounded: "xl",
      },
    },
    icons: {
      defaultSet: "md",
      aliases,
      sets: {
        md,
      },
    },
    theme: {
      defaultTheme: "light",
      themes: {
        light: lightTheme,
        dark: darkTheme,
      },
    },
    components: { VTimePicker, VNumberInput },
    // date: {
    //   adapter: DayJsAdapter,
    // },
  });

  vueApp.use(vuetify);
});
