<template>
  <PageLoader :loading="loading">
    <EventDiscountPage v-if="event" />
  </PageLoader>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import PageLoader from "@/components/pages/PageLoader";
import gql from "graphql-tag";
import type {
  EventDiscountCheck,
  EventDiscountCheckTicket,
} from "@/models/event";
import EventDiscountPage from "@/components/pages/event/EventDiscountPage.vue";
import { useAvailability } from "@/services/resource-availability";

const CHECK_QUERY = gql`
  mutation ($token: String!) {
    checkEventDiscount(token: $token) {
      event
      tickets {
        id
        gid
        name
        description
        quantity
      }
    }
  }
`;

export default defineComponent({
  components: {
    PageLoader,
    EventDiscountPage,
  },
  async setup() {
    definePageMeta({
      layout: "footer",
    });

    const route = useRoute();
    const token = computed(() => String(route.params.token));
    const checking = ref(false);
    const fetching = ref(false);
    const discountedTickets = ref<EventDiscountCheckTicket[]>([]);

    provide("token", token);

    const salesClient = useSalesClient();
    const store = useEventCheckoutStore();
    const { event, availability } = storeToRefs(store);
    const resourceAvailability = useAvailability();

    const handle404 = () => {
      nextTick(() => {
        throw createError({ statusCode: 404 });
      });
    };

    const getEvent = (handle: string) => {
      store.handle = handle;
      fetching.value = true;
      store.loadingAvailability = true;

      store
        .fetch(handle)
        .catch(handle404)
        .finally(() => {
          fetching.value = false;
        });

      resourceAvailability
        .event(handle)
        .then((result) => {
          availability.value = result;
        })
        .catch(() => {
          // TODO: log error
        })
        .finally(() => {
          store.loadingAvailability = false;
        });
    };

    const checkDiscount = () => {
      checking.value = true;
      salesClient
        .mutate<{ checkEventDiscount: EventDiscountCheck }, { token: string }>({
          mutation: CHECK_QUERY,
          variables: {
            token: token.value,
          },
        })
        .then(({ data }) => {
          if (!data?.checkEventDiscount) {
            handle404();
          } else {
            store.setDiscountTickets(data.checkEventDiscount.tickets);
            getEvent(data.checkEventDiscount.event);
          }
        })
        .catch(handle404)
        .finally(() => {
          checking.value = false;
        });
    };

    onMounted(checkDiscount);

    const loading = computed(() => checking.value || fetching.value);

    return {
      loading,
      event,
      discountedTickets,
    };
  },
});
</script>
