import { defineComponent, type PropType, type VNode } from "vue";
import { VSpacer } from "vuetify/components";
import BlockComponent from "./BlockComponent.vue";
import type { ComponentBlock } from "./types";
import AppLink, { type LinkAction } from "@/components/elements/AppLink";

export interface SectionContent {
  title: string;
  content: ComponentBlock;
  description?: string;
  containCopy?: ComponentBlock["contain"];
  action?: LinkAction;
  tag?: string;
  style?: Record<string, any>;
}

declare module "blocks" {
  export interface ComponentBlocks {
    "section-block": SectionContent;
  }
}

export default defineComponent({
  name: "SectionBlock",
  props: {
    content: { type: Object as PropType<SectionContent>, required: true },
  },
  render() {
    const containCopy = this.content.containCopy || true;
    const copyNodes: VNode[] = [];

    copyNodes.push(
      h("div", { class: "flex" }, [
        h("h3", this.content.title),
        h(VSpacer),
        this.content.action
          ? h(AppLink, {
              dotted: true,
              link: this.content.action,
            })
          : undefined,
      ])
    );

    if (this.content.description) {
      copyNodes.push(
        h("p", { class: "mt-1", innerHTML: this.content.description })
      );
    }

    return h(
      this.content.tag || "div",
      {
        class: "py-10",
        style: this.content.style,
      },
      [
        h(
          "div",
          {
            class: {
              "v-container": containCopy === true,
              [`use-container-${containCopy}`]: typeof containCopy === "string",
            },
          },
          copyNodes
        ),
        h(BlockComponent, { content: this.content.content }),
      ]
    );
  },
});
