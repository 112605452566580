import { useApolloClient } from "@vue/apollo-composable";
import type { Currency } from "../types";

// TODO: resolve currency
export const resolveCurrency = (code: string): Currency => {
  const currencies = getCurrencies();
  const c = findBy(currencies, "code", code);
  return c || currencies[0];
};

export const getCurrencies = (): Currency[] => {
  return [
    {
      code: "AUD",
      symbol: "$",
      name: "AU Dollar",
    },
    {
      code: "USD",
      symbol: "$",
      name: "US Dollar",
    },
    // {
    //   code: "EUR",
    //   symbol: "€",
    //   name: "Euro",
    // },
  ];
};

export const client = () => {
  const { client } = useApolloClient(SalesApolloClient.toString());
  return client;
};

export const SalesApolloClient = Symbol("sales-apollo-client");
