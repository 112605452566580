<template>
  <PricingIntro :loading="loading" :plans="plans" />
  <PricingFaqs />
  <GetStarted class="guide-divider" pricing-page />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import PricingIntro from "@/components/sections/PricingIntro.vue";
import PricingFaqs from "@/components/sections/PricingFaqs.vue";
import GetStarted from "@/components/sections/GetStarted.vue";

export default defineComponent({
  components: {
    PricingIntro,
    PricingFaqs,
    GetStarted,
  },
  setup() {
    const keywords = seoKeywords();

    definePageMeta({
      headerDark: true,
      layout: "sell",
      seo: {
        title: "Orie Pricing | Free Trial",
        description:
          "Set up your business, pick a free or paid plan later. No credit card required.",
        keywords: [
          "Orie pricing",
          "Orie price",
          "Orie free",
          ...keywords.sell.home,
        ],
      },
    });

    const { loading, plans } = usePlans();

    return {
      plans,
      loading,
    };
  },
});
</script>
