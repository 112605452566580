<template>
  <PageLoader :loading="!loaded">
    <ServicePage :show-menu="showMenu" :show-return="showReturn" />
  </PageLoader>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { storeToRefs } from "pinia";
import {
  MULTI_SERVICE_ROUTE_KEY,
  SERVICE_ROUTE_KEY,
  queryAvailability,
} from "@/components/pages/service/lib";
import ServicePage from "@/components/pages/service";
import { useServiceBookingStore } from "@/stores/service-booking";
import PageLoader from "@/components/pages/PageLoader";
import type { ServiceAvailabilityScheduleOptions } from "@/services/service-booking";

export default defineComponent({
  components: {
    ServicePage,
    PageLoader,
  },
  setup() {
    definePageMeta({
      layout: "service",
      disableHeaderStick: true,
      noMobileNav: true,
    });

    const store = useServiceBookingStore();
    const route = useRoute();

    const provider = String(route.params.provider);
    if (route.params.reschedule) {
      store.reschedule = String(route.params.reschedule);
    }

    queryAvailability(provider, () => {
      const handle = String(route.params.service);
      const isMultiple = handle === MULTI_SERVICE_ROUTE_KEY;
      let service: ServiceAvailabilityScheduleOptions["service"];
      let variants: ServiceAvailabilityScheduleOptions["variants"];

      if (isMultiple) {
        variants = String(route.query[SERVICE_ROUTE_KEY] || "").split(",");
      } else if (isNumeric(handle)) {
        variants = [handle];
      } else {
        service = handle;
      }

      return {
        service,
        variants,
        timezone: clientTimezone(),
      };
    });

    const { loaded } = storeToRefs(store);

    // SEO, meta tags
    // Apply no-crawl for meetings

    const showMenu = computed(() => {
      return route.query.menu !== "false";
    });

    const showReturn = computed(() => {
      return !showMenu.value && route.query.return !== "false";
    });

    return {
      loaded,
      showMenu,
      showReturn,
    };
  },
});
</script>
