<template>
  <PageLoader :loading="loading">
    <ShopPage />
  </PageLoader>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ShopPage from "@/components/pages/shop";
import PageLoader from "@/components/pages/PageLoader";
import { useShopProfileStore } from "@/stores/shop-profile";
import gql from "graphql-tag";
import type { ShopProfile } from "@/components/pages/shop/lib";

const mediaQuery = "id fqfn type src thumb alt";
const productsQuery = `gid name handle
          price price_min price_max
          # rating{ value count }
          image{${mediaQuery}}
          gallery{${mediaQuery}}`;

const QUERY = gql`
  query ($handle: String!) {
    shopProfile(handle: $handle) {
      handle
      name
      bio
      avatar{${mediaQuery}}
      gallery{${mediaQuery}}
      followersCount
      ordersCount
      serviceProfileHandle
      about
      location {
        name
        addressLine
        phone
        geo{ lat lng }
      }
      products {
        count
        products{${productsQuery}}
        collections{
          name description count
          items{${productsQuery}}
        }
      }
      services {
        count
        services{
          name handle gender
          kind provider age_restriction
          video_url purchase_action
          price price_min
          group{ id name description position color }
          image{${mediaQuery}}
          gallery{${mediaQuery}}
          # serviceProfile{ }
          variants{ id name pricing_type price duration image{${mediaQuery}} gallery{${mediaQuery}} }
        }
      }
      events {
        count
        events{
          image{${mediaQuery}}
          handle name
          starts_at ends_at hide_start_time hide_end_time
          online
          venue{ name description address_line }
          tickets{ id type }
          age_restriction purchase_action
        }
      }
    }
  }
`;

export default defineComponent({
  components: { ShopPage, PageLoader },
  setup() {
    definePageMeta({
      disableHeaderStick: true,
    });

    const route = useRoute();
    const store = useShopProfileStore();
    const salesClient = useSalesClient();

    const handle = String(route.params.handle);
    store.handle = handle;
    const loading = ref(false);

    // const { loading, onResult, onError } = useQuery<{
    //   shopProfile: ShopProfile;
    // }>(QUERY, {
    //   handle,
    // });

    const handleNotFound = () => {
      //
    };

    loading.value = true;
    salesClient
      .query<{ shopProfile: ShopProfile }, { handle: string }>({
        query: QUERY,
        variables: {
          handle,
        },
      })
      .then(({ data }) => {
        if (data?.shopProfile) {
          store.update(data.shopProfile);
        } else {
          return Promise.reject();
        }
      })
      .catch(handleNotFound)
      .finally(() => {
        loading.value = false;
      });

    // onError(handleNotFound);
    // onResult((result) => {
    //   if (result.data?.shopProfile) {
    //     store.update(result.data.shopProfile);
    //   } else {
    //     handleNotFound();
    //   }
    // });

    return {
      loading,
    };
  },
});
</script>
