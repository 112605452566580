import type { ComponentBlocks } from "blocks";
import { defineComponent, type PropType } from "vue";
import type { Event } from "@/models/event";
import type { EventQueryFilter } from "@/types/models";
import Grid, { type GridLayout } from "../grid/Grid.block";
import Slider, { type SliderContent } from "../slider";
import type { EventContentLayout, EventSourceType } from "./Event.block";

export interface EventsContent {
  layout?: GridLayout | "slider";
  layoutOptions?: ComponentBlocks["grid-block"]["layoutOptions"];
  itemLayout?: EventContentLayout;
  slider?: Omit<SliderContent, "columns">;
  data: string[] | Event[] | EventQueryFilter;
}

declare module "blocks" {
  export interface ComponentBlocks {
    "events-block": EventsContent;
  }
}

export default defineComponent({
  name: "EventsBlock",
  props: {
    content: {
      type: Object as PropType<EventsContent>,
      required: true,
    },
  },
  setup(props) {
    let data: Ref<(string | Event)[]>;

    if (Array.isArray(props.content.data)) {
      data = ref(props.content.data);
    } else {
      // fetch data
      const apiData = useEvents({
        filter: props.content.data,
      });
      data = apiData.events;
    }

    return {
      data,
    };
  },
  render() {
    const columns = this.data.map((source: EventSourceType<any>) =>
      defineComponentBlock({
        component: "event-block",
        layout: this.content.itemLayout,
        source,
      })
    );

    if (this.content.layout === "slider") {
      return h(Slider, {
        content: {
          ...this.content.slider,
          columns,
        },
      });
    }

    return h(Grid, {
      content: {
        layout: this.content.layout,
        layoutOptions: this.content.layoutOptions,
        columns,
      },
    });
  },
});
