<template>
  <div>
    <v-container>
      <div class="mb-12 space-y-2">
        <h2 class="text-3xl font-bold md:text-4xl">Orie Events</h2>
      </div>
      <ContentLoader :loading="loading">
        <div class="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
          <EventCard
            v-for="event in events"
            :key="event.handle"
            class="group p-6 sm:p-8"
            :source="event"
          />
        </div>
      </ContentLoader>
    </v-container>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import EventCard from "@/components/blocks/event/EventCard.vue";
import { useSalesApi } from "@/services/sales-api";
import ContentLoader from "@/components/elements/ContentLoader.vue";

export default defineComponent({
  components: { ContentLoader, EventCard },
  async setup() {
    definePageMeta({
      layout: "app",
      headerDark: true,
      seo: {
        title: "Events",
      },
    });

    const api = useSalesApi();

    const { loading, result, handle } = observeAsync(api.events.list);
    const events = computed(() => result.value || []);

    handle([
      "handle",
      "name",
      "image",
      "tickets.id",
      "hide_start_time",
      "hide_end_time",
      "occurrences.name",
      "occurrences.starts_at",
      "occurrences.ends_at",
      "occurrences.all_day",
      "occurrences.starts_at",
      "occurrences.ends_at",
      "occurrences.location",
      "price_min",
      "price",
      "currency_code",
    ]);

    return {
      loading,
      events,
    };
  },
});
</script>
