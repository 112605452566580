<template>
  <v-dialog v-model="show" fullscreen>
    <v-sheet height="100%" color="black">
      <v-container class="h-100 overflow-hidden">
        <v-card-actions>
          <v-spacer />
          <v-btn size="large" icon @click="exit">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-actions>
        <div class="absolute inset-0 pointer-events-none px-5">
          <div class="h-full center-content">
            <div class="pointer-events-auto md:max-w-500px">
              <h2 class="text-7 mb-4">Start your free 14-day trial of Orie</h2>
              <VDynamicForm
                v-model="intent"
                :disabled="loading"
                :inputs="inputs"
              >
                <template #after="{ meta: { valid } }">
                  <v-btn
                    color="primary"
                    :disabled="!valid"
                    :loading="loading"
                    type="submit"
                    size="large"
                    class="mt-4"
                    block
                    @click="registerIntent"
                  >
                    Get started
                  </v-btn>
                </template>
              </VDynamicForm>
            </div>
          </div>
        </div>
      </v-container>
    </v-sheet>
  </v-dialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import gql from "graphql-tag";
import { useMutation } from "@vue/apollo-composable";
import { VDynamicForm } from "#components";
import { has } from "lodash";

const SIGNUP_INTENT_MUTATION = gql`
  mutation ($data: SignupIntentInput!) {
    signupIntent(data: $data)
  }
`;

export default defineComponent({
  components: { VDynamicForm },
  layout: "blank",
  setup() {
    const router = useRouter();

    definePageMeta({
      headerDark: true,
      seo: {
        title: "Register for an account | Free Trial",
      },
    });

    const { mutate, loading } = useMutation<{
      signupIntent: string;
      data: any;
    }>(SIGNUP_INTENT_MUTATION);

    const exit = () => {
      router.go(-1);
    };
    return {
      getIntent: mutate,
      loading,
      exit,
    };
  },
  data: () => ({
    show: false,
    intent: {
      period: 12,
      trial: false,
    } as {
      period: number;
      trial: boolean;
      email?: string;
      plan?: string;
    },
    inputs: {
      email: {
        name: "Email",
        type: "text",
        rules: "required|email",
        props: {
          placeholder: "Email address",
        },
      },
      name: {
        name: "Name",
        type: "text",
        rules: "max:64",
        props: {
          placeholder: "Your name",
        },
      },
    },
  }),
  mounted() {
    const route = useRoute();
    const query = Object.assign({}, route.query);

    if (query.email) this.intent.email = String(query.email);
    if (query.plan) this.intent.plan = String(query.plan);
    if (query.period) {
      if (query.period == "monthly") this.intent.period = 1;
      else if (query.period == "yearly") this.intent.period = 12;
    }

    if (has(query, "trial")) {
      this.intent.trial = true;
    }

    if (!query.email) {
      if (query.plan) {
        // enter email
        this.show = true;
      } else {
        this.redirectToDashboard();
      }
    } else {
      this.registerIntent();
    }
  },
  methods: {
    registerIntent() {
      this.getIntent({
        data: {
          ...this.intent,
          account_type: "SELLER",
        },
      })
        .then((data) => {
          this.redirectToDashboard(data?.data?.signupIntent);
        })
        .catch((error) => {
          console.error("signup intent error", error);
        });
    },
    redirectToDashboard(intent?: string) {
      const config = useRuntimeConfig();

      if (intent) {
        // redirect to dashboard register with token
        window.location.href = getUrl(
          config.public.links.dashboard,
          "register",
          { intent }
        );
      } else {
        // redirect to dashboard register
        window.location.href = getUrl(
          config.public.links.dashboard,
          "register"
        );
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.h-100 {
  height: 100%;
}
.form-container {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  pointer-events: none;
  .form-content {
    pointer-events: all;
  }
}
</style>
