<template>
  <ContactSection />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ContactSection from "@/components/sections/Contact.vue";

export default defineComponent({
  components: { ContactSection },
  setup() {
    definePageMeta({
      layout: "sell",
      headerDark: true,
      noAppHeaderPadding: true,
      seo: {
        title: "Contact Us",
      },
    });
  },
});
</script>
