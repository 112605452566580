<template>
  <div>
    <BannerHero />
    <BrandsSection />
    <CommerceFeatures />
    <PlatformAudience />
    <CommerceComparison />
    <GetStarted class="guide-divider" heading="Start selling with Orie." />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import BannerHero from "@/components/heros/CommerceBanner.vue";
import CommerceFeatures from "@/components/sections/CommerceFeatures.vue";
import BrandsSection from "@/components/sections/Brands.vue";
import PlatformAudience from "@/components/sections/PlatformAudience.vue";
import CommerceComparison from "@/components/sections/CommerceComparison.vue";
import GetStarted from "@/components/sections/GetStarted.vue";

export default defineComponent({
  components: {
    BannerHero,
    BrandsSection,
    CommerceFeatures,
    PlatformAudience,
    CommerceComparison,
    GetStarted,
  },
  setup() {
    const keywords = seoKeywords();

    definePageMeta({
      layout: "sell",
      seo: {
        title: "Start and grow your e-commerce business",
        description:
          "Learn how to sell with Orie and open your store today. Start selling online to local and international customers.",
        keywords: keywords.sell.commerce,
      },
    });
  },
});
</script>
