import {
  isEqual,
  transform,
  isObject,
  get,
  set,
  isString,
  omitBy,
  isUndefined,
  has,
} from "lodash";

export const empty = (value: any): boolean => {
  if (typeof value === "boolean") return false;
  if (typeof value === "string") return !value.length;
  if (typeof value === "number") return false;
  return !!value;
};

export const equals = (a: any, b: any): boolean => {
  if (typeof a === "object") {
    if (typeof b !== "object") return false;
    return isEqual(a, b);
  }
  return a === b;
};

export const flattenObject = (object: any) => {
  const toReturn: any = {};

  for (const i in object) {
    if (!has(object, i)) continue;

    if (typeof object[i] == "object") {
      const flatObject = flattenObject(object[i]);
      for (const x in flatObject) {
        if (!has(flatObject, x)) continue;
        toReturn[i + "." + x] = flatObject[x];
      }
    } else {
      toReturn[i] = object[i];
    }
  }
  return toReturn;
};

export const isChanged = (original: any, object: any): boolean => {
  if (!original || !object) return false;
  if (!isObject(object)) {
    if (object === undefined) return false;
    return original !== object;
  }

  let changed = false;
  for (const key of Object.keys(flattenObject(object))) {
    const value = get(object, key);
    changed = changed || (!empty(value) && !isEqual(value, get(original, key)));
    if (changed) break;
  }

  return changed;
};

export function changes<T extends Record<string, any>>(
  object: T,
  original: T
): Partial<T> {
  return transform(object, function (result: any, value: any, key: string) {
    if (!isEqual(value, original[key])) {
      result[key] =
        isObject(value) && isObject(original[key])
          ? changes(value, original[key])
          : value;
    }
  });
}

/**
 * Deep diff between two object, using lodash
 * @param  {Object} object    Object compared
 * @param  {Object} original  Object to compare with
 * @return {Object}           Return a new object who represent the diff
 */
export function deepDiff<T extends Record<string, any>>(
  object: T,
  original: T
) {
  return changes(object, original);
}

export function assignObject<
  A extends Record<string, any>,
  B extends Record<string, any>,
>(a: A, b: B) {
  Object.entries(b).forEach(([key, value]) => {
    set(a, key, value);
  });
}

export function parseJsonString(value: any): any {
  if (isObject(value)) {
    return value;
  }
  if (isString(value)) {
    try {
      value = JSON.parse(value);
    } catch {
      value = undefined;
    }
  }
  return value;
}

export const filterUndefined = <T>(o: T): T => omitBy<any>(o, isUndefined) as T;
