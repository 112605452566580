import { defineComponent, type PropType } from "vue";
import type { Recipe } from "@/models/recipe";
import type { RecipeQueryFilter } from "@/types/models";
import Grid, { type GridLayout } from "../grid/Grid.block";
import type { RecipeContentLayout, SourceType } from "./Recipe.block";
import Slider, { type SliderContent } from "../slider";
import type { ComponentBlocks } from "blocks";

export interface RecipesContent {
  layout?: GridLayout | "slider";
  layoutOptions?: ComponentBlocks["grid-block"]["layoutOptions"];
  itemLayout?: RecipeContentLayout;
  slider?: Omit<SliderContent, "columns">;
  data: string[] | Recipe[] | RecipeQueryFilter;
}

declare module "blocks" {
  export interface ComponentBlocks {
    "recipes-block": RecipesContent;
  }
}

export default defineComponent({
  name: "RecipesBlock",
  props: {
    content: {
      type: Object as PropType<RecipesContent>,
      required: true,
    },
  },
  setup(props) {
    let data: Ref<(string | Recipe)[]>;

    if (Array.isArray(props.content.data)) {
      data = ref(props.content.data);
    } else {
      // fetch data
      const apiData = useRecipes({
        filter: props.content.data,
      });
      data = apiData.recipes;
    }

    return {
      data,
    };
  },
  render() {
    const columns = this.data.map((source: SourceType<any>) =>
      defineComponentBlock({
        component: "recipe-block",
        layout: this.content.itemLayout,
        source,
      })
    );

    if (this.content.layout === "slider") {
      return h(Slider, {
        content: {
          ...this.content.slider,
          columns,
        },
      });
    }

    return h(Grid, {
      content: {
        layout: this.content.layout,
        layoutOptions: this.content.layoutOptions,
        columns,
      },
    });
  },
});
