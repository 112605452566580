<template>
  <div
    class="relative h-full md:bg-gray/20 md:flex md:justify-center md:items-center"
  >
    <div class="lt-md:h-full md:w-[600px]">
      <v-sheet
        v-if="isEmpty"
        class="md:rounded-lg! pa-4 md:pa-10 md:overflow-hidden text-center h-full flex! justify-center items-center!"
      >
        <v-progress-circular
          v-if="fetching"
          indeterminate
          width="1"
          size="70"
        />
        <div v-else>
          <Logo />
          <div class="mt-4 text-5.5">Your Cart is Empty.</div>
          <v-btn class="mt-10" color="black" size="large" href="/">
            Continue shopping
          </v-btn>
        </div>
      </v-sheet>
      <template v-else>
        <v-sheet
          class="lt-md:sticky lt-md:top-0 lt-md:z-10 px-2 py-4 flex! md:bg-transparent! items-center"
        >
          <v-btn href="/" icon="close" variant="flat" color="transparent" />
          <h1 class="text-5 ml-2 my-0">Cart</h1>
        </v-sheet>
        <v-sheet class="md:rounded-lg! md:overflow-hidden">
          <v-progress-linear v-if="loading" indeterminate width="1" />
          <div class="md:h-350px overflow-y-auto pa-4 lt-md:pt-10">
            <div
              v-for="(item, i) in items"
              :key="i"
              :class="{ 'border-t': i }"
              class="py-2"
            >
              <div class="flex flex-row justify-between gap-4">
                <div>{{ item.name }}</div>
                <v-avatar v-if="item.image" size="50" rounded="lg">
                  <MediaBlock
                    :content="{
                      media: item.image,
                      imageOptions: {
                        fit: 'contain',
                      },
                    }"
                  />
                </v-avatar>
              </div>
              <div class="mt-1 flex justify-between">
                <div class="w-150px">
                  <QuantityInput
                    :model-value="item.quantity"
                    min="0"
                    size="small"
                    disabled
                  />
                </div>
                <div class="font-semibold">
                  {{ currency.symbol }}{{ item.total }}
                </div>
              </div>
            </div>
            <v-divider />
            <div class="px-4 py-2">
              <!-- <div
              class="bg-gray/20 rounded-lg flex justify-between items-center px-2 py-3 mb-2"
            >
              <span>Add note</span>
              <v-icon>add</v-icon>
            </div> -->
              <div class="flex justify-between items-center px-2 py-3">
                <div class="text-4 font-semibold">Subtotal</div>
                <div class="text-4">{{ currency.symbol }}{{ subtotal }}</div>
              </div>
              <p class="mt-2 px-2 text-3.4">
                Shipping, taxes and discounts calculated at checkout.
              </p>
            </div>
          </div>
          <v-sheet class="sticky bottom-0 left-0 right-0">
            <v-divider />
            <div class="px-4 py-2">
              <v-btn
                @click="checkout"
                :loading="checkingOut"
                :disabled="loading"
                color="black"
                size="large"
                block
              >
                Continue to checkout
                <v-icon class="ml-2" size="20">shopping_cart</v-icon>
              </v-btn>
            </div>
          </v-sheet>
        </v-sheet>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { CartCheckoutRequestStatus } from "@/modules/sales/src/types";
import QuantityInput from "@/components/inputs/QuantityInput.vue";

export default defineComponent({
  components: { QuantityInput },
  setup() {
    definePageMeta({
      layout: "blank",
      seo: {
        title: "Cart",
      },
    });

    const route = useRoute();
    const { currency } = useCurrency();
    const {
      items,
      fetching,
      loading,
      checkingOut,
      isEmpty,
      subtotal,
      loadOnce,
      checkout: _checkout,
    } = useCart();

    const checkout = async () => {
      const result = await _checkout();
      if (result?.status == CartCheckoutRequestStatus.DELIVERY_DATE_REQUIRED) {
        console.log("DELIVERY_DATE_REQUIRED");
      } else if (result?.checkoutUrl) {
        window.location.href = result.checkoutUrl!;
      } else if (result?.status == CartCheckoutRequestStatus.ACCEPTED) {
        // redirect to success page
      } else {
        // error
      }
    };

    onMounted(async () => {
      await loadOnce();
      if (import.meta.client && route.query.checkout) {
        checkout();
      }
    });

    return {
      currency,
      items,
      fetching,
      loading,
      checkingOut,
      isEmpty,
      subtotal,
      checkout,
    };
  },
});
</script>
