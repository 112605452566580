<template>
  <div>
    <BannerHero />
    <EventFeatures />
    <EventComparison />
    <EventPricingCalculator />
    <GetStarted
      class="guide-divider"
      heading="Start organising with Orie."
      caption="Get started for free and get instant access to the tools you need to plan a successful event and grow your business."
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import BannerHero from "@/components/heros/EventsBanner.vue";
import EventFeatures from "@/components/sections/EventFeatures.vue";
import EventComparison from "@/components/sections/EventComparison.vue";
import EventPricingCalculator from "@/components/sections/EventPricingCalculator.vue";
import GetStarted from "@/components/sections/GetStarted.vue";

export default defineComponent({
  components: {
    BannerHero,
    EventFeatures,
    EventComparison,
    EventPricingCalculator,
    GetStarted,
  },
  setup() {
    const keywords = seoKeywords();

    definePageMeta({
      layout: "sell",
      seo: {
        title: "Event Ticketing & Booking Platform",
        description:
          "Sell event tickets to your next big event. Orie is easy-to-use and has the lowest ticket booking fees",
        keywords: keywords.sell.events,
      },
    });
  },
});
</script>
