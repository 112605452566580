<template>
  <div class="py-20">
    <div
      class="bg-black text-white w-90px fixed top-0 right-4 rounded-b-lg px-4 py-2 text-3 line-height-3 text-center z-10"
    >
      Preview
    </div>
    <ProductPage v-if="isProduct" :product="data" />
    <EventPage v-else-if="isEvent" :preview="data" />
    <ServicePage v-else-if="isService" :preview="data" />
    <v-dialog :model-value="loading" fullscreen>
      <div class="bg-white w-full h-full flex items-center justify-center">
        <v-progress-circular indeterminate width="2" size="70" />
      </div>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import type { PublishableModel } from "@/models/model";
import ProductPage from "@/components/pages/product";
import EventPage from "@/components/pages/event/preview";
import ServicePage from "@/components/pages/service/preview";

type PreviewResult = {
  expiry: number;
  data: PublishableModel;
};

export default defineComponent({
  components: { ProductPage, EventPage, ServicePage },
  async setup() {
    definePageMeta({
      layout: "blank",
    });

    const route = useRoute();
    const data = ref<PublishableModel>();
    const loading = ref(true);

    const error = (): never => {
      loading.value = false;
      throw createError({ statusCode: 404 });
    };

    const session = route.query.s as string;
    const sourceUrl = serverApiUrl("preview", session);

    const isProduct = computed(() => data.value?.object === "product");
    const isEvent = computed(() => data.value?.object === "event");
    const isService = computed(() => data.value?.object === "service");

    $fetch<PreviewResult>(sourceUrl)
      .then((result) => {
        if (!result || isExpired(result.expiry)) error();
        loading.value = false;
        data.value = result.data;
      })
      .catch(error);

    return {
      data,
      loading,
      isProduct,
      isEvent,
      isService,
    };
  },
});
</script>
