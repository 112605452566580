<template>
  <PageContent v-if="story" :content="story.content" />
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  async setup() {
    definePageMeta({
      layout: "app",
    });

    // const story = definePageContent([
    //   {
    //     component: "grid-block",
    //     contain: true,
    //     layout: "l5",
    //     columns: [
    //       {
    //         component: "image-block",
    //         src: "https://images.unsplash.com/photo-1599360889420-da1afaba9edc?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NjZ8fHByb2R1Y3R8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60",
    //         link: "orie://marketplace/product/1234",
    //       },
    //       {
    //         component: "image-block",
    //         src: "https://images.unsplash.com/photo-1586495777744-4413f21062fa?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8OHx8cHJvZHVjdHxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60",
    //         link: "orie://marketplace/product/1234",
    //       },
    //       {
    //         component: "image-block",
    //         src: "https://images.unsplash.com/photo-1503602642458-232111445657?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8OXx8cHJvZHVjdHxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60",
    //         link: "orie://marketplace/product/1234",
    //       },
    //       {
    //         component: "video-block",
    //         src: "https://player.vimeo.com/external/429052631.sd.mp4?s=9b1bd2a9174a27b63092034789e5aef6d9cc0f82&profile_id=165&oauth2_token_id=57447761",
    //         repeat: true,
    //         controls: false,
    //         link: "orie://marketplace/product/1234",
    //       },
    //       {
    //         component: "image-block",
    //         src: "https://images.unsplash.com/photo-1542291026-7eec264c27ff?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTB8fHByb2R1Y3R8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60",
    //         link: "orie://marketplace/product/1234",
    //       },
    //     ],
    //   },
    //   {
    //     component: "products-block",
    //     contain: "md",
    //     layout: "collection",
    //     itemLayout: "standard",
    //     data: fakeProducts(7),
    //   },
    // ]);

    const client = useMeilisearch();
    const { data } = await useAsyncData("products", () => client.product.all());

    const story = computed(() =>
      definePageContent([
        {
          component: "products-block",
          contain: "md",
          layout: "collection",
          itemLayout: "standard",
          data: data.value?.results || [],
        },
      ])
    );

    return {
      story,
    };
  },
});
</script>
