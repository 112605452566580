<template>
  <div>
    <v-container>
      <div class="mb-12 space-y-2">
        <h2 class="text-3xl font-bold md:text-4xl">Orie Services</h2>
      </div>
      <div class="md:grid md:gap-8 md:grid-cols-2 lg:grid-cols-3">
        <ServiceProfileCard
          v-for="profile in profiles"
          :key="profile.handle"
          class="group p-6 sm:p-8"
          :source="profile"
        />
      </div>
    </v-container>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import type { ServiceProfile } from "@/models/service";
import ServiceProfileCard from "@/components/blocks/service-profile/ServiceProfileCard.vue";
import { useSalesApi } from "@/services/sales-api";

export default defineComponent({
  components: { ServiceProfileCard },
  async setup() {
    definePageMeta({
      layout: "app",
      headerDark: true,
      seo: {
        title: "Services",
      },
    });

    const api = useSalesApi();
    const { loading, result, handle } = observeAsync(api.services.list);

    const profiles = computed(() => {
      const services = [...(result.value || [])];
      const p: Record<string, ServiceProfile> = {};

      for (const service of services) {
        // currently not making meetings public by default
        const key = service.serviceProfile.handle;
        p[key] = {
          ...(p[key] || { services: [] }),
          ...filterUndefined(service.serviceProfile),
        };
        p[key].services.push(service);
      }

      return Object.values(p);
    });

    handle();

    // SEO, meta

    return {
      loading,
      profiles,
    };
  },
});
</script>
