import { get } from "lodash";

export const wrap = <T>(items?: T | T[]): T[] => {
  if (!items) return [];
  return Array.isArray(items) ? items : [items];
};

export const unique = <T extends string | number>(items: T[]): T[] => [
  ...new Set(items),
];

export const sortBy = <K extends string, T extends { [k in K]: number }>(
  items: T[],
  key: K
): T[] => {
  return items.sort((a, b) => get(a, key) - get(b, key));
};

export const someTrue = (v: boolean[]) => v.some((x) => x === true);

export const someFalse = (v: boolean[]) => v.some((x) => x === false);

export const mapBy = <T = any, K extends keyof T = keyof T>(
  items: T[] | undefined,
  key: K
): T[K][] => {
  return wrap(items).map((item) => get(item, key));
};

function findBy<T = any, K extends keyof T = keyof T>(
  items: T[] | undefined,
  key: K,
  value: T[K]
): T | undefined;
function findBy<T = any, K extends keyof T = keyof T>(
  items: T[] | undefined,
  key: [K, ...string[]],
  value: any
): T | undefined;
function findBy<T = any, K extends keyof T = keyof T>(
  items: T[] | undefined,
  key: K | [K, ...string[]],
  value: any
): T | undefined {
  return wrap(items).find((item) => get(item, key) == value);
}

export { findBy };
