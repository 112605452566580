<template>
  <PageLoader :loading="loading">
    <EventPage v-if="event" :event="event" />
  </PageLoader>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { storeToRefs } from "pinia";
import EventPage from "@/components/pages/event";
import jsonLd from "@/services/jsonld/event";
import { useEventCheckoutStore } from "@/stores/event-checkout";
import { useAvailability } from "@/services/resource-availability";
import PageLoader from "@/components/pages/PageLoader";

export default defineComponent({
  components: {
    EventPage,
    PageLoader,
  },
  async setup() {
    definePageMeta({
      // disable sticky in header
      disableHeaderStick: true,
      headerDark: true,
    });

    const store = useEventCheckoutStore();
    const route = useRoute();
    const { trackEventView } = usePageview();
    const resourceAvailability = useAvailability();

    const handle404 = (): never => {
      throw createError({ statusCode: 404, statusMessage: "Event Not Found" });
    };

    const handle = String(route.params.handle);
    store.handle = handle;

    const { loading, event, availability } = storeToRefs(store);

    store.loadingAvailability = true;
    resourceAvailability
      .event(handle)
      .then((result) => {
        availability.value = result;
      })
      .catch(() => {
        // TODO: log error
      })
      .finally(() => {
        store.loadingAvailability = false;
      });

    // const data = useAsyncData("event:" + handle, () => client.event.find(handle));

    onMounted(() => {
      store.fetch(handle).then(trackEventView).catch(handle404);
    });

    useJsonld(jsonLd(event));

    useSeoMeta({
      title: event.value?.seo_title || event.value?.name,
      ogTitle: event.value?.seo_title || event.value?.name,
      description:
        event.value?.seo_description ||
        event.value?.summary ||
        event.value?.description,
      ogDescription:
        event.value?.seo_description ||
        event.value?.summary ||
        event.value?.description,
      publisher: event.value?.organiser?.name,
      ogImage: event.value?.image?.src,
      twitterCard: "summary_large_image",
    });

    return {
      event,
      loading,
    };
  },
});
</script>
